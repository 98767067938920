import React from "react"
import styles from "./BalanceHeaderComponent.module.scss"
import { useCoreThemeProvider } from "../../../../../admin/core/presentation/contexts/CoreThemeProviderContext"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import { Link } from "react-router-dom"
import SegmentControlComponent, {
  SegmentControlItem
} from "../../../../../admin/lib/segment-control/SegmentControlComponent"

interface HeaderAction {
  readonly name: string
  readonly url?: string | null
}

interface Props {
  readonly ownerName?: string
  readonly formattedBalanceValue?: string
  readonly action?: HeaderAction
  readonly needShowAction?: boolean
  readonly segmentControlItems: SegmentControlItem[]
  readonly selectedSegmentControlItem?: SegmentControlItem
  readonly onSelectSegmentControlItem: (segmentItem: SegmentControlItem) => void
}

export default function BalanceHeaderComponent({
  ownerName = "",
  formattedBalanceValue = "",
  action,
  needShowAction = true,
  segmentControlItems,
  selectedSegmentControlItem,
  onSelectSegmentControlItem
}: Props) {
  const coreTheme = useCoreThemeProvider()

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <h1 className={`${styles.carrierName} ${coreTheme.headline5PrimaryClassName()}`}>
              {ownerName}
            </h1>
            <div className={coreTheme.headline6PrimaryClassName()}>
              {withRubbleSymbol(formattedBalanceValue)}
            </div>
            {action?.url && needShowAction && (
              <Link
                to={action.url}
                className={coreTheme.body1LinkClassName()}
              >
                {action.name}
              </Link>
            )}
          </div>
          <div className={styles.segmentsContainer}>
            <SegmentControlComponent
              items={segmentControlItems}
              selectedItem={selectedSegmentControlItem}
              componentStyles={coreTheme.segmentControl1PrimaryStyle()}
              onSelectSegment={(item) => {
                onSelectSegmentControlItem(item)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
