import NetworkFuelCompanyErrorsObjectAttributes from "./NetworkFuelCompanyErrorsObjectAttributes"
import { Expose, Type } from "class-transformer"
import FuelCompanyErrorsObject from "../../../../../domain/fuel-companies/FuelCompanyErrorsObject"
import NetworkLegalEntityErrorsObject from "../../../legal-entities/NetworkLegalEntityErrorsObject"
import NetworkContactPersonErrorsObject from "../../../contact-people/NetworkContactPersonErrorsObject"

export default class NetworkFuelCompanyErrorsObject implements FuelCompanyErrorsObject {
  @Expose()
  @Type(() => NetworkFuelCompanyErrorsObjectAttributes)
  readonly attributes?: NetworkFuelCompanyErrorsObjectAttributes | null

  @Expose()
  @Type(() => NetworkLegalEntityErrorsObject)
  readonly legalEntity?: NetworkLegalEntityErrorsObject | null

  @Expose()
  @Type(() => NetworkContactPersonErrorsObject)
  readonly contactPerson?: NetworkContactPersonErrorsObject | null
}
