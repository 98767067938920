import NetworkGasStation from "../entities/gas-stations/NetworkGasStation"
import GasStation from "../../domain/gas-stations/GasStation"
import FuelCompaniesMapper from "./FuelCompaniesMapper"
import GPSLocationsMapper from "./GPSLocationsMapper"

export default class GasStationsMapper {
  mapNetworkToDomain({
    gasStation
  }: {
    readonly gasStation: NetworkGasStation
  }): GasStation {
    const { fuelCompany, location } = gasStation
    return {
      id: gasStation.id,
      name: gasStation.name,
      code: gasStation.code,
      blocked: gasStation.blocked,
      address: gasStation.address,
      location: location && new GPSLocationsMapper().mapNetworkToDomain({ location }),
      phoneNumber: gasStation.phoneNumber,
      fuelCompanyId: gasStation.fuelCompanyId,
      fuelCompany: fuelCompany && new FuelCompaniesMapper().mapNetworkToDomain({ fuelCompany })
    }
  }

  mapDomainToNetwork({
    gasStation
  }: {
    readonly gasStation: GasStation
  }): NetworkGasStation {
    const { fuelCompany, location } = gasStation
    return {
      id: gasStation.id,
      name: gasStation.name,
      code: gasStation.code,
      blocked: gasStation.blocked,
      address: gasStation.address,
      location: location && new GPSLocationsMapper().mapDomainToNetwork({ location }),
      phoneNumber: gasStation.phoneNumber,
      fuelCompanyId: gasStation.fuelCompanyId,
      fuelCompany: fuelCompany && new FuelCompaniesMapper().mapDomainToNetwork({ fuelCompany })
    }
  }
}
