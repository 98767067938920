import LegalEntitiesMapper from "./LegalEntitiesMapper"
import ContactPeopleMapper from "./ContactPeopleMapper"
import FuelCompany from "../../domain/fuel-companies/FuelCompany"
import NetworkFuelCompany from "../entities/fuel-companies/NetworkFuelCompany"
import FuelCompanyBalancesMapper from "./FuelCompanyBalancesMapper"

export default class FuelCompaniesMapper {
  mapNetworkToDomain({
    fuelCompany
  }: {
    readonly fuelCompany: NetworkFuelCompany
  }): FuelCompany {
    const {
      legalEntity,
      contactPerson,
      balance: fuelCompanyBalance
    } = fuelCompany

    return {
      id: fuelCompany.id,
      name: fuelCompany.name,
      code: fuelCompany.code,
      blocked: fuelCompany.blocked,
      legalEntity: legalEntity && new LegalEntitiesMapper().mapNetworkToDomain({ legalEntity }),
      contactPerson: contactPerson && new ContactPeopleMapper().mapNetworkToDomain({ contactPerson }),
      balance: fuelCompanyBalance && new FuelCompanyBalancesMapper().mapNetworkToDomain({ fuelCompanyBalance })
    }
  }

  mapDomainToNetwork({
    fuelCompany
  }: {
    readonly fuelCompany: FuelCompany
  }): NetworkFuelCompany {
    const { legalEntity, contactPerson } = fuelCompany
    return {
      name: fuelCompany.name,
      code: fuelCompany.code,
      blocked: fuelCompany.blocked,
      legalEntity: legalEntity && new LegalEntitiesMapper().mapDomainToNetwork({ legalEntity }),
      contactPerson: contactPerson && new ContactPeopleMapper().mapDomainToNetwork({ contactPerson })
    }
  }
}
