import FuelPricesRepository from "../../domain/repositories/FuelPricesRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultFuelPricesRepository from "../../data/repositories/DefaultFuelPricesRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import GasStationsForFuelPricesRepository from "../../domain/repositories/GasStationsForFuelPricesRepository"
import DefaultGasStationsForFuelPricesRepository
  from "../../data/repositories/DefaultGasStationsForFuelPricesRepository"
import FuelsForFuelPricesRepository from "../../domain/repositories/FuelsForFuelPricesRepository"
import DefaultFuelsForFuelPricesRepository from "../../data/repositories/DefaultFuelsForFuelPricesRepository"

export default interface FuelPricesDataDiModule {
  provideFuelPricesRepository(): FuelPricesRepository

  provideGasStationsForFuelPricesRepository(): GasStationsForFuelPricesRepository

  provideFuelsForFuelPricesRepository(): FuelsForFuelPricesRepository
}

export class DefaultFuelPricesDataDiModule extends DiModule implements FuelPricesDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideFuelPricesRepository(): FuelPricesRepository {
    return this.single(
      DefaultFuelPricesRepository.name,
      () => new DefaultFuelPricesRepository({
        fuelPricesNetworkSource: this.appDataDiModule.provideFuelPricesNetworkSource()
      })
    )
  }

  provideGasStationsForFuelPricesRepository(): GasStationsForFuelPricesRepository {
    return this.single(
      DefaultGasStationsForFuelPricesRepository.name,
      () => new DefaultGasStationsForFuelPricesRepository({
        gasStationsNetworkSource: this.appDataDiModule.provideGasStationsNetworkSource()
      })
    )
  }

  provideFuelsForFuelPricesRepository(): FuelsForFuelPricesRepository {
    return this.single(
      DefaultFuelsForFuelPricesRepository.name,
      () => new DefaultFuelsForFuelPricesRepository({
        fuelsNetworkSource: this.appDataDiModule.provideFuelsNetworkSource()
      })
    )
  }
}
