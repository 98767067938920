import FuelCompaniesForFuelCompanyBalancesRepository
  from "../../domain/repositories/FuelCompaniesForFuelCompanyBalancesRepository"
import {
  GetFuelCompanyForFuelCompanyBalancesParameters
} from "../../domain/use-cases/fuel-companies/GetFuelCompanyForFuelCompanyBalancesUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import FuelCompaniesNetworkSource from "../../../../core/data/sources/network/FuelCompaniesNetworkSource"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompaniesMapper from "../../../../core/data/mappers/FuelCompaniesMapper"

export default class DefaultFuelCompaniesForFuelCompanyBalancesRepository
  implements FuelCompaniesForFuelCompanyBalancesRepository {
  private readonly fuelCompaniesNetworkSource: FuelCompaniesNetworkSource

  constructor(parameters: {
    readonly fuelCompaniesNetworkSource: FuelCompaniesNetworkSource
  }) {
    this.fuelCompaniesNetworkSource = parameters.fuelCompaniesNetworkSource
  }

  async getFuelCompany({
    objectId
  }: GetFuelCompanyForFuelCompanyBalancesParameters): Promise<GetObjectResult<FuelCompany>> {
    const mapper = new FuelCompaniesMapper()
    const result = await this.fuelCompaniesNetworkSource.getFuelCompany({
      fuelCompanyId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            fuelCompany: result.data.fuelCompany!
          })
        }
      default:
        return result
    }
  }
}
