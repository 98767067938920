import React, { useEffect, useState } from "react"
import styles from "./DatePickerComponent.module.scss"
import DropdownComponent, { DropdownWidthMode } from "../../dropdown/DropdownComponent"
import { SingleDateCalendarComponent } from "../single-calendar/SingleDateCalendarComponent"
import { CalendarComponentStyles } from "../calendar/CalendarComponent"
import DateTimeFormatter from "../../DateTimeFormatter"
import TextInputComponentStyle from "../../text-input/TextInputComponentStyles"
import DateInputComponent from "../../text-input/DateInputComponent"
import { PickerClearButtonStyles } from "../../picker/PickerComponentStyles"
import isPresent from "../../isPresent"
import ButtonComponent from "../../button/ButtonComponent"
import { TimeInputComponent } from "../../text-input/TimeInputComponent"
import isBlank from "../../isBlank"

export interface DatePickerComponentStyle {
  readonly clearButton?: PickerClearButtonStyles
  readonly input?: TextInputComponentStyle
  readonly dropdownClassName?: string
  readonly calendar?: CalendarComponentStyles
}

export default function DatePickerComponent({
  name,
  value,
  onChange,
  timeZone,
  placeholder = "",
  weekDayNameByNumber,
  monthNameByNumber,
  disabled = false,
  hasErrors = false,
  clearable = false,
  withTime = false,
  componentStyle = {}
}: {
  readonly name: string
  readonly value: Date | null
  readonly timeZone?: string
  readonly placeholder?: string
  readonly onChange: (date: Date | null) => void
  readonly weekDayNameByNumber: { [_: number]: string }
  readonly monthNameByNumber: { [_: number]: string }
  readonly clearable?: boolean
  readonly disabled?: boolean
  readonly hasErrors?: boolean
  readonly withTime?: boolean
  readonly componentStyle?: DatePickerComponentStyle
}) {
  const [isCalendarVisible, setIsCalendarVisible] = useState(false)
  const [formattedDate, setFormattedDate] = useState<string | null>(null)
  const [formattedTime, setFormattedTime] = useState<string | null>(null)

  useEffect(() => {
    if (isBlank(value)) {
      setFormattedDate(null)
      setFormattedTime(null)
      return
    }

    if (isBlank(formattedDate) && isPresent(value)) {
      setFormattedDate(new DateTimeFormatter(value, timeZone).formatDate())
    }

    if (isBlank(formattedTime) && isPresent(value)) {
      setFormattedTime(new DateTimeFormatter(value, timeZone).formatTime())
    }
  }, [value])

  const {
    clearButton: {
      iconClassName,
      styles: clearButtonStyles
    } = {}
  } = componentStyle

  useEffect(() => {
    if (disabled && isCalendarVisible) {
      setIsCalendarVisible(false)
    }
  }, [disabled])

  function handleOnInputChange(stringValue: string) {
    if (stringValue === "") {
      onDataChanged(null)
    } else {
      const parts = stringValue.split(".")
      const date = new Date(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10)
      )

      if (isPresent(value)) {
        date?.setHours(value.getHours(), value.getMinutes(), value.getSeconds())
      }

      onDataChanged(date)
    }

    setIsCalendarVisible(false)
  }

  function handleOnTimeInputChange(timeValue: string) {
    const dateValue = isPresent(value) ? new Date(value.getTime()) : new Date()
    const parts = timeValue.split(":")
    const hours = parseInt(isPresent(parts[0]) ? parts[0] : "0")
    const minutes = parseInt(isPresent(parts[1]) ? parts[1] : "0")

    dateValue.setHours(hours, minutes, 0, 0)
    onDataChanged(dateValue)
  }

  function handleOnClick() {
    if (disabled) return

    setIsCalendarVisible(!isCalendarVisible)
  }

  function handleOnClearClick() {
    onDataChanged(null)
  }

  function handleDropdownRequestHide() {
    hideCalendarAndChangeDate(value)
  }

  function hideCalendarAndChangeDate(date: Date | null) {
    setIsCalendarVisible(false)
    if (isPresent(value)) {
      date?.setHours(value.getHours(), value.getMinutes(), value.getSeconds())
    }

    onDataChanged(date)
  }

  function onDataChanged(date: Date | null) {
    const oldValue = isPresent(value) ? new DateTimeFormatter(value).formatDateTime() : ""
    const newValue = isPresent(date) ? new DateTimeFormatter(date).formatDateTime() : ""

    if (oldValue === newValue) return

    setFormattedDate(date && new DateTimeFormatter(date).formatDate())
    setFormattedTime(date && new DateTimeFormatter(date).formatTime())

    onChange(date)
  }

  return (
    <div className={styles.root}>
      <div className={styles.changeDateContainer}>
        <div className={styles.inputContainer}>
          <DateInputComponent
            name={name}
            value={formattedDate}
            isDisabled={disabled}
            placeholder={placeholder}
            isActive={isCalendarVisible}
            onClick={handleOnClick}
            onChange={handleOnInputChange}
            className={styles.inputWrapper}
            componentStyle={componentStyle.input}
            hasErrors={hasErrors}
          />
          {withTime && (
            <TimeInputComponent
              name={name}
              value={formattedTime}
              isDisabled={disabled}
              placeholder={placeholder}
              onChange={handleOnTimeInputChange}
              className={styles.inputWrapper}
              componentStyle={{
                ...componentStyle.input,
                icons: {}
              }}
              hasErrors={hasErrors}
            />
          )}
        </div>
      </div>
      <DropdownComponent
        className={`${styles.dropdown} ${componentStyle.dropdownClassName}`}
        widthMode={DropdownWidthMode.WRAP_CONTENT}
        isVisible={isCalendarVisible}
        hideOnClickOutside={true}
        requestHide={handleDropdownRequestHide}
      >
        <SingleDateCalendarComponent
          componentStyles={componentStyle.calendar}
          date={value}
          onChange={hideCalendarAndChangeDate}
          weekDayNameByNumber={weekDayNameByNumber}
          monthNameByNumber={monthNameByNumber}
        />
      </DropdownComponent>
      {clearable && isPresent(value) && (
        <ButtonComponent
          icon={<div className={iconClassName} />}
          componentStyles={clearButtonStyles}
          isDisabled={disabled}
          onClick={handleOnClearClick}
        />
      )}
    </div>
  )
}
