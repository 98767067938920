import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import GasStationBreaksI18n from "../../i18n/GasStationBreaksI18n"
import isBlank from "../../../../../admin/lib/isBlank"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import GetGasStationsForGasStationBreaksUseCase from "../../domain/use-cases/gas-stations/GetGasStationsForGasStationBreaksUseCase"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import GasStationBreak from "../../../../core/domain/gas-station-breaks/GasStationBreak"
import GasStationBreaksFilter from "../../../../core/domain/gas-station-breaks/GasStationBreaksFilter"

export default class GasStationBreaksTableProvider implements TableProvider<GasStationBreak, GasStationBreaksFilter> {
  private readonly coreI18n: CoreI18n
  private readonly gasStationBreaksI18n: GasStationBreaksI18n
  private readonly getGasStationsUseCase: GetGasStationsForGasStationBreaksUseCase
  private readonly timeZone: string

  constructor(parameters: {
    readonly timeZone: string
    readonly coreI18n: CoreI18n
    readonly gasStationBreaksI18n: GasStationBreaksI18n
    readonly getGasStationsUseCase: GetGasStationsForGasStationBreaksUseCase
  }) {
    this.timeZone = parameters.timeZone
    this.coreI18n = parameters.coreI18n
    this.gasStationBreaksI18n = parameters.gasStationBreaksI18n
    this.getGasStationsUseCase = parameters.getGasStationsUseCase
  }

  getEntity(): string {
    return Entity.GAS_STATION_BREAKS
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getTable(): Table<GasStationBreak, GasStationBreaksFilter> {
    const appUrlProvider = new AppUrlProvider()
    const gasStationBreaksTextProvider = this.gasStationBreaksI18n.getTextProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()

    return new Table<GasStationBreak, GasStationBreaksFilter>({
      title: gasStationBreaksTextProvider.listTitle(),
      getObjectId: (gasStationBreak: GasStationBreak) => gasStationBreak.id!.toString(),
      getRowUrl: (gasStationBreak: GasStationBreak) => appUrlProvider.buildShowGasStationBreakUrl({
        gasStationBreakId: gasStationBreak.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewGasStationBreaksUrl(),
      columns: [
        new TableColumn<GasStationBreak>({
          name: "gasStation",
          entity: Entity.GAS_STATIONS,
          title: gasStationBreaksTextProvider.gasStationNameField(),
          createValue: (gasStationBreak: GasStationBreak) => new TextTableValue({
            value: gasStationBreak.gasStation?.name,
            url: (() => {
              const { gasStation } = gasStationBreak
              if (isBlank(gasStation)) return null

              return appUrlProvider.buildShowGasStationUrl({
                gasStationId: gasStation.id!
              })
            })()
          })
        }),
        new TableColumn<GasStationBreak>({
          name: "beginDateTime",
          title: gasStationBreaksTextProvider.beginDateTimeField(),
          isSortingAvailable: false,
          createValue: (gasStationBreak: GasStationBreak) => new DateTableValue({
            value: gasStationBreak.beginDateTime,
            format: DateTimeFormat.DATE_TIME
          })
        }),
        new TableColumn<GasStationBreak>({
          name: "endDateTime",
          title: gasStationBreaksTextProvider.endDateTimeField(),
          isSortingAvailable: false,
          createValue: (gasStationBreak: GasStationBreak) => new DateTableValue({
            value: gasStationBreak.endDateTime,
            format: DateTimeFormat.DATE_TIME
          })
        }),
        new TableColumn<GasStationBreak>({
          name: "value",
          title: gasStationBreaksTextProvider.commentField(),
          isSortingAvailable: false,
          createValue: (gasStationBreak: GasStationBreak) => new TextTableValue({
            value: gasStationBreak.comment
          })
        })
      ],
      filter: new Filter<GasStationBreaksFilter>({
        buildFilterObject: (): GasStationBreaksFilter => ({}),
        fields: [
          new SingleSelectFormField<GasStationBreaksFilter, void, GasStation>({
            title: gasStationBreaksTextProvider.gasStationFilter(),
            entity: Entity.GAS_STATIONS,
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getId: () => "gasStationId",
            getValue: (filter: GasStationBreaksFilter) => filter.gasStation,
            setValue: (
              filter: GasStationBreaksFilter,
              gasStation: GasStation | null
            ): GasStationBreaksFilter => ({
              ...filter,
              gasStation,
              gasStationId: gasStation?.id
            }),
            getObjectsUseCase: this.getGasStationsUseCase,
            getOptionId: (optionObject: GasStation) => optionObject.id!.toString(),
            getOptionText: (optionObject: GasStation) => optionObject.name
          })
        ]
      })
    })
  }
}
