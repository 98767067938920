import NetworkFuelCompanyAppAdministrator
  from "../entities/fuel-company-app-administrator/response-bodies/NetworkFuelCompanyAppAdministrator"
import FuelCompanyAppAdministrator from "../../domain/fuel-company-app-administrators/FuelCompanyAppAdministrator"
import FuelCompaniesMapper from "./FuelCompaniesMapper"

export default class FuelCompanyAppAdministratorsMapper {
  mapNetworkToDomain({
    fuelCompanyAppAdministrator
  }: {
    readonly fuelCompanyAppAdministrator: NetworkFuelCompanyAppAdministrator
  }): FuelCompanyAppAdministrator {
    const { fuelCompany } = fuelCompanyAppAdministrator
    return {
      id: fuelCompanyAppAdministrator.id,
      isOwner: fuelCompanyAppAdministrator.isOwner,
      fuelCompanyId: fuelCompanyAppAdministrator.fuelCompanyId,
      fuelCompany: fuelCompany && new FuelCompaniesMapper().mapNetworkToDomain({ fuelCompany })
    }
  }

  mapDomainToNetwork({
    fuelCompanyAppAdministrator
  }: {
    readonly fuelCompanyAppAdministrator: FuelCompanyAppAdministrator
  }): NetworkFuelCompanyAppAdministrator {
    return {
      isOwner: fuelCompanyAppAdministrator.isOwner,
      fuelCompanyId: fuelCompanyAppAdministrator.fuelCompanyId
    }
  }
}
