import GasStationBreaksRepository from "../../domain/repositories/GasStationBreaksRepository"
import { GetGasStationBreaksParameters } from "../../domain/use-cases/gas-station-breaks/GetGasStationBreaksUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateGasStationBreakParameters } from "../../domain/use-cases/gas-station-breaks/CreateGasStationBreakUseCase"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetGasStationBreakParameters } from "../../domain/use-cases/gas-station-breaks/GetGasStationBreakUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateGasStationBreakParameters } from "../../domain/use-cases/gas-station-breaks/UpdateGasStationBreakUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import FuelPrice from "../../../../core/domain/fuel-prices/FuelPrice"
import isPresent from "../../../../../admin/lib/isPresent"
import GasStationBreaksNetworkSource from "../../../../core/data/sources/network/GasStationBreaksNetworkSource"
import GasStationBreak from "../../../../core/domain/gas-station-breaks/GasStationBreak"
import NetworkGasStationBreaksRequestFilter
  from "../../../../core/data/entities/gas-station-breaks/request-queries/NetworkGasStationBreaksRequestFilter"
import GasStationBreaksMapper from "../../../../core/data/mappers/GasStationBreaksMapper"
import GasStationBreakError from "../../../../core/domain/gas-station-breaks/GasStationBreakError"

export default class DefaultGasStationBreaksRepository implements GasStationBreaksRepository {
  private readonly gasStationBreaksNetworkSource: GasStationBreaksNetworkSource

  constructor(parameters: {
    readonly gasStationBreaksNetworkSource: GasStationBreaksNetworkSource
  }) {
    this.gasStationBreaksNetworkSource = parameters.gasStationBreaksNetworkSource
  }

  async getGasStationBreaks({
    filter,
    pagination,
    sort
  }: GetGasStationBreaksParameters): Promise<GetObjectsResult<GasStationBreak>> {
    const gasStationId = filter?.gasStationId
    const result = await this.gasStationBreaksNetworkSource.getGasStationBreaks({
      filter: new NetworkGasStationBreaksRequestFilter({
        gasStationIds: isPresent(gasStationId) ? [gasStationId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.gasStationBreaks!.map((gasStationBreak) => {
              return new GasStationBreaksMapper().mapNetworkToDomain({ gasStationBreak })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getGasStationBreak({
    objectId
  }: GetGasStationBreakParameters): Promise<GetObjectResult<FuelPrice>> {
    const result = await this.gasStationBreaksNetworkSource.getGasStationBreak({
      gasStationBreakId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new GasStationBreaksMapper().mapNetworkToDomain({
            gasStationBreak: result.data.gasStationBreak!
          })
        }
      default:
        return result
    }
  }

  async createGasStationBreak({
    object: gasStationBreak
  }: CreateGasStationBreakParameters): Promise<CreateObjectResult<GasStationBreak, GasStationBreakError>> {
    const mapper = new GasStationBreaksMapper()
    const result = await this.gasStationBreaksNetworkSource.createGasStationBreak({
      gasStationBreak: mapper.mapDomainToNetwork({ gasStationBreak })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            gasStationBreak: result.data.gasStationBreak!
          })
        }
      default:
        return result
    }
  }

  async updateGasStationBreak({
    objectId: gasStationBreakId,
    object: gasStationBreak
  }: UpdateGasStationBreakParameters): Promise<UpdateObjectResult<GasStationBreak, GasStationBreakError>> {
    const mapper = new GasStationBreaksMapper()
    const result = await this.gasStationBreaksNetworkSource.updateGasStationBreak({
      gasStationBreakId: parseInt(gasStationBreakId),
      gasStationBreak: mapper.mapDomainToNetwork({
        gasStationBreak
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            gasStationBreak: result.data.gasStationBreak!
          })
        }
      default:
        return result
    }
  }
}
