import TableProvider from "../../../../../admin/features/objects/presentation/providers/TableProvider"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import Table from "../../../../../admin/features/objects/presentation/entities/tables/Table"
import TableColumn, {
  TableColumnAlignment
} from "../../../../../admin/features/objects/presentation/entities/tables/TableColumn"
import TextTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/TextTableValue"
import FuelPricesI18n from "../../i18n/FuelPricesI18n"
import isBlank from "../../../../../admin/lib/isBlank"
import FuelPrice from "../../../../core/domain/fuel-prices/FuelPrice"
import DateTableValue
  from "../../../../../admin/features/objects/presentation/entities/tables/table-value-by-type/DateTableValue"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import FuelPricesFilter from "../../../../core/domain/fuel-prices/FuelPricesFilter"
import GetGasStationsForFuelPriceUseCase from "../../domain/use-cases/gas-stations/GetGasStationsForFuelPriceUseCase"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import Filter from "../../../../../admin/features/objects/presentation/entities/filters/Filter"
import Fuel from "../../../../core/domain/fuels/Fuel"
import GetFuelsForFuelPriceUseCase from "../../domain/use-cases/fuels/GetFuelsForFuelPriceUseCase"
import withRubbleSymbol from "../../../../../admin/lib/withRubbleSymbol"
import CoreI18n from "../../../../../admin/core/i18n/CoreI18n"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

export default class FuelPricesTableProvider implements TableProvider<FuelPrice, FuelPricesFilter> {
  private readonly coreI18n: CoreI18n
  private readonly fuelPricesI18n: FuelPricesI18n
  private readonly getGasStationsUseCase: GetGasStationsForFuelPriceUseCase
  private readonly getFuelsForFuelPriceUseCase: GetFuelsForFuelPriceUseCase

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly fuelPricesI18n: FuelPricesI18n
    readonly getGasStationsUseCase: GetGasStationsForFuelPriceUseCase
    readonly getFuelsForFuelPriceUseCase: GetFuelsForFuelPriceUseCase
  }) {
    this.coreI18n = parameters.coreI18n
    this.fuelPricesI18n = parameters.fuelPricesI18n
    this.getGasStationsUseCase = parameters.getGasStationsUseCase
    this.getFuelsForFuelPriceUseCase = parameters.getFuelsForFuelPriceUseCase
  }

  getEntity(): string {
    return Entity.FUEL_PRICES
  }

  searchByQueryIsEnable(): boolean {
    return false
  }

  getTable(): Table<FuelPrice, FuelPricesFilter> {
    const appUrlProvider = new AppUrlProvider()
    const fuelPricesTextProvider = this.fuelPricesI18n.getTextProvider()
    const coreTextProvider = this.coreI18n.getTextProvider()

    return new Table<FuelPrice, FuelPricesFilter>({
      title: fuelPricesTextProvider.listTitle(),
      getObjectId: (fuelPrice: FuelPrice) => fuelPrice.id!.toString(),
      getRowUrl: (fuelPrice: FuelPrice) => appUrlProvider.buildShowFuelPriceUrl({
        fuelPriceId: fuelPrice.id!
      }),
      newObjectPageUrl: appUrlProvider.buildNewFuelPriceUrl(),
      columns: [
        new TableColumn<FuelPrice>({
          name: "fuelCompany",
          entity: Entity.FUEL_COMPANIES,
          title: fuelPricesTextProvider.fuelCompanyField(),
          createValue: (fuelPrice: FuelPrice) => new TextTableValue({
            value: fuelPrice.gasStation?.fuelCompany?.name,
            url: (() => {
              const { gasStation } = fuelPrice
              const fuelCompany = gasStation?.fuelCompany

              if (isBlank(fuelCompany)) return null

              return appUrlProvider.buildShowFuelCompanyUrl({
                fuelCompanyId: fuelCompany.id!
              })
            })()
          })
        }),
        new TableColumn<FuelPrice>({
          name: "gasStation",
          entity: Entity.GAS_STATIONS,
          title: fuelPricesTextProvider.gasStationNameField(),
          createValue: (fuelPrice: FuelPrice) => new TextTableValue({
            value: fuelPrice.gasStation?.name,
            url: (() => {
              const { gasStation } = fuelPrice
              if (isBlank(gasStation)) return null

              return appUrlProvider.buildShowGasStationUrl({
                gasStationId: gasStation.id!
              })
            })()
          })
        }),
        new TableColumn<FuelPrice>({
          name: "fuelType",
          title: fuelPricesTextProvider.fuelTypeField(),
          createValue: (fuelPrice: FuelPrice) => new TextTableValue({
            value: fuelPrice.fuel?.name
          })
        }),
        new TableColumn<FuelPrice>({
          name: "startDate",
          title: fuelPricesTextProvider.startDatePriceField(),
          isSortingAvailable: true,
          createValue: (fuelPrice: FuelPrice) => new DateTableValue({
            value: fuelPrice.startDate,
            format: DateTimeFormat.DATE
          })
        }),
        new TableColumn<FuelPrice>({
          name: "value",
          title: fuelPricesTextProvider.priceField(),
          isSortingAvailable: true,
          columnAlignment: TableColumnAlignment.RIGHT,
          createValue: (fuelPrice: FuelPrice) => new TextTableValue({
            value: fuelPrice.formattedValue && withRubbleSymbol(fuelPrice.formattedValue)
          })
        })
      ],
      filter: new Filter<FuelPricesFilter>({
        buildFilterObject: (): FuelPricesFilter => ({}),
        fields: [
          new SingleSelectFormField<FuelPricesFilter, void, GasStation>({
            title: fuelPricesTextProvider.gasStationFilter(),
            entity: Entity.GAS_STATIONS,
            groupName: "",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getId: () => "gasStationId",
            getValue: (filter: FuelPricesFilter) => filter.gasStation,
            setValue: (
              filter: FuelPricesFilter,
              gasStation: GasStation | null
            ): FuelPricesFilter => ({
              ...filter,
              gasStation,
              gasStationId: gasStation?.id
            }),
            getObjectsUseCase: this.getGasStationsUseCase,
            getOptionId: (optionObject: GasStation) => optionObject.id!.toString(),
            getOptionText: (optionObject: GasStation) => optionObject.name
          }),
          new SingleSelectFormField<FuelPricesFilter, void, Fuel>({
            title: fuelPricesTextProvider.fuelTypeField(),
            entity: Entity.FUELS,
            groupName: "",
            getId: () => "fuelId",
            placeholder: coreTextProvider.unlimited(),
            clearable: true,
            getValue: (filter: FuelPricesFilter) => filter.fuel,
            setValue: (
              filter: FuelPricesFilter,
              fuel: Fuel | null
            ): FuelPricesFilter => ({
              ...filter,
              fuel,
              fuelId: fuel?.id
            }),
            getObjectsUseCase: this.getFuelsForFuelPriceUseCase,
            getOptionId: (fuel: Fuel) => fuel.id!.toString(),
            getOptionText: (fuel: Fuel) => fuel.name
          })
        ]
      })
    })
  }
}
