import FuelPricesTextProvider from "../FuelPricesTextProvider"

export default class DefaultRuFuelPricesTextProvider implements FuelPricesTextProvider {

  existObjectTitle(): string {
    return "Цена"
  }

  newObjectTitle(): string {
    return "Новая цена"
  }

  listTitle(): string {
    return "Цены"
  }

  gasStationNameField(): string {
    return "Наименование АЗС"
  }

  fuelCompanyField(): string {
    return "Топливная компания"
  }

  fuelTypeField(): string {
    return "Вид топлива"
  }

  startDatePriceField(): string {
    return "Дата начала действия цены"
  }

  priceField(): string {
    return "Цена стелы"
  }

  gasStationFilter(): string {
    return "АЗС"
  }
}
