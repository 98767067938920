import AppI18 from "../../../i18n/AppI18"
import isPresent from "../../../../../admin/lib/isPresent"
import InstallationInstanceType, { InstallationInstanceTypeSelectOption } from "../../../domain/installation-instance/InstallationInstanceType"

export default class InstallationInstanceTypesStaticSource {
  private readonly appI18n: AppI18

  constructor(parameters: {
    readonly appI18n: AppI18
  }) {
    this.appI18n = parameters.appI18n
  }

  async getInstallationInstances({
    ids
  }: {
    readonly ids?: string[] | null
  }): Promise<InstallationInstanceTypeSelectOption[]> {
    const values = [
      {
        id: InstallationInstanceType.METHANIKA_REFUELLER,
        text: this.appI18n.getTextProvider().installationInstanceTypeMethanikaRefuellerText()
      },
      {
        id: InstallationInstanceType.SHELF_GAS_STATION,
        text: this.appI18n.getTextProvider().installationInstanceTypeShelfGasStationText()
      },
      {
        id: InstallationInstanceType.SERVIO_PUMP_GAS,
        text: this.appI18n.getTextProvider().installationInstanceTypeServioPumpGasText()
      },
      {
        id: InstallationInstanceType.RODNIK_2,
        text: this.appI18n.getTextProvider().installationInstanceTypeRodnik2Text()
      },
      {
        id: InstallationInstanceType.SNCARD,
        text: this.appI18n.getTextProvider().installationInstanceTypeSnCardText()
      },
      {
        id: InstallationInstanceType.TOPAZ,
        text: this.appI18n.getTextProvider().installationInstanceTypeTopazText()
      }
    ]

    if (isPresent(ids)) {
      return values.filter((value) => ids.includes(value.id))
    }

    return values
  }
}
