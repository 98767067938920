import { Expose, Type } from "class-transformer"
import NetworkGasStation from "../gas-stations/NetworkGasStation"

export default class NetworkGasStationBreak {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Type(() => Date)
  readonly beginDateTime?: Date | null

  @Expose()
  @Type(() => Date)
  readonly endDateTime?: Date | null

  @Expose()
  @Type(() => String)
  readonly comment?: string | null

  @Expose()
  @Type(() => Number)
  readonly gasStationId?: number | null

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStation?: NetworkGasStation | null

  // constructor(parameters: {
  //   readonly id?: number | null
  //   readonly beginDateTime?: Date | null
  //   readonly endDateTime?: Date | null
  //   readonly comment?: string | null
  //   readonly gasStationId?: number | null
  //   readonly gasStation?: NetworkGasStation | null
  // }) {
  //   this.id = parameters.id
  //   this.beginDateTime = parameters.beginDateTime
  //   this.endDateTime = parameters.endDateTime
  //   this.gasStation = parameters.gasStation
  //   this.comment = parameters.comment
  //   this.gasStationId = parameters.gasStationId
  // }
}
