import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../../../errors/NetworkAttributeError"
import FuelCompanyErrorsObjectAttributes from "../../../../../domain/fuel-companies/FuelCompanyErrorsObjectAttributes"

export default class NetworkFuelCompanyErrorsObjectAttributes implements FuelCompanyErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name?: NetworkAttributeError[] | null

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly code?: NetworkAttributeError[] | null
}
