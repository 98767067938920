import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import FuelPricesI18n from "../../i18n/FuelPricesI18n"
import SingleSelectFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/SingleSelectFormField"
import FuelPrice from "../../../../core/domain/fuel-prices/FuelPrice"
import FuelPriceError from "../../../../core/domain/fuel-prices/FuelPriceError"
import FuelPriceErrorsObject from "../../../../core/domain/fuel-prices/FuelPriceErrorsObject"
import GetGasStationsForFuelPriceUseCase from "../../domain/use-cases/gas-stations/GetGasStationsForFuelPriceUseCase"
import GasStation from "../../../../core/domain/gas-stations/GasStation"
import DateFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DateFormField"
import DecimalFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import Fuel from "../../../../core/domain/fuels/Fuel"
import GetFuelsForFuelPriceUseCase from "../../domain/use-cases/fuels/GetFuelsForFuelPriceUseCase"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

const mainGroupName = "main"

export default class FuelPriceFormProvider
  implements FormProvider<FuelPrice, FuelPriceError, FuelPriceErrorsObject> {
  private readonly timeZone: string
  private readonly fuelPricesI18: FuelPricesI18n
  private readonly getGasStationsForFuelPriceUseCase: GetGasStationsForFuelPriceUseCase
  private readonly getFuelsForFuelPriceUseCase: GetFuelsForFuelPriceUseCase

  constructor(parameters: {
    readonly timeZone: string
    readonly fuelPricesI18: FuelPricesI18n
    readonly getGasStationsForFuelPriceUseCase: GetGasStationsForFuelPriceUseCase
    readonly getFuelsForFuelPriceUseCase: GetFuelsForFuelPriceUseCase
  }) {
    this.timeZone = parameters.timeZone
    this.fuelPricesI18 = parameters.fuelPricesI18
    this.getGasStationsForFuelPriceUseCase = parameters.getGasStationsForFuelPriceUseCase
    this.getFuelsForFuelPriceUseCase = parameters.getFuelsForFuelPriceUseCase
  }

  getEntity(): string {
    return Entity.FUEL_PRICES
  }

  getNewObjectTitle(): string {
    return this.fuelPricesI18.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle(): string {
    return this.fuelPricesI18.getTextProvider().existObjectTitle()
  }

  getExistedObjectTitle(): string {
    return this.fuelPricesI18.getTextProvider().existObjectTitle()
  }

  async buildObject(): Promise<FuelPrice> {
    return {}
  }

  getErrorsObject({ error }: { readonly error?: FuelPriceError }): FuelPriceErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    return [
      {
        name: mainGroupName,
        visible: false
      }
    ]
  }

  getFields(): FormField<FuelPrice, FuelPriceErrorsObject>[] {
    const fuelPricesTextProvider = this.fuelPricesI18.getTextProvider()

    return filterNotEmpty([
      new SingleSelectFormField<FuelPrice, FuelPriceErrorsObject, GasStation>({
        title: fuelPricesTextProvider.gasStationNameField(),
        required: true,
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getGasStationsForFuelPriceUseCase,
        getErrors: (errorsObject?: FuelPriceErrorsObject) => errorsObject?.attributes?.gasStationId,
        getId: () => "gasStationId",
        getOptionId: (gasStation: GasStation) => gasStation.id!.toString(),
        getOptionText: (gasStation: GasStation) => gasStation.name,
        getValue: (fuelPrice: FuelPrice) => fuelPrice.gasStation,
        setValue: (fuelPrice: FuelPrice, gasStation: GasStation | null) => ({
          ...fuelPrice, gasStation, gasStationId: gasStation?.id
        })
      }),
      new SingleSelectFormField<FuelPrice, FuelPriceErrorsObject, Fuel>({
        title: fuelPricesTextProvider.fuelTypeField(),
        required: true,
        clearable: false,
        groupName: mainGroupName,
        getObjectsUseCase: this.getFuelsForFuelPriceUseCase,
        getErrors: (errorsObject?: FuelPriceErrorsObject) => errorsObject?.attributes?.fuelId,
        getId: () => "fuelId",
        getOptionId: (fuel: Fuel) => fuel.id!.toString(),
        getOptionText: (fuel: Fuel) => fuel.name,
        getValue: (fuelPrice: FuelPrice) => fuelPrice.fuel,
        setValue: (fuelPrice: FuelPrice, fuel: Fuel | null) => ({
          ...fuelPrice, fuel, fuelId: fuel?.id
        })
      }),
      new DateFormField<FuelPrice, FuelPriceErrorsObject>({
        title: fuelPricesTextProvider.startDatePriceField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "startDate",
        getValue: (fuelPrice: FuelPrice) => fuelPrice.startDate,
        setValue: (fuelPrice: FuelPrice, startDate: Date | null | undefined) => ({ ...fuelPrice, startDate }),
        getErrors: (errorsObject?: FuelPriceErrorsObject) => errorsObject?.attributes?.startDate
      }),
      new DecimalFormField<FuelPrice, FuelPriceErrorsObject>({
        title: fuelPricesTextProvider.priceField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "value",
        getValue: (fuelPrice: FuelPrice) => fuelPrice.value,
        setValue: (fuelPrice: FuelPrice, value: Decimal | null | undefined) => ({ ...fuelPrice, value }),
        getErrors: (errorsObject?: FuelPriceErrorsObject) => errorsObject?.attributes?.value
      })
    ])
  }
}
