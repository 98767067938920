import isBlank from "../../isBlank"
import isPresent from "../../isPresent"

const yearCharactersCount = 4
const monthCharactersCount = 2
const dayCharactersCount = 2
const hoursCharactersCount = 2
const minutesCharactersCount = 2
const secondsCharactersCount = 2

const millisecondsInSecond = 1000
const secondsInMinute = 60
const minutesInHour = 60
const hoursInDay = 24
const millisecondsInHour = millisecondsInSecond * secondsInMinute * minutesInHour
const millisecondsInDay = millisecondsInHour * hoursInDay

// TODO: leave here only lib methods. Create app wrapper with specific logic outside

export default class ExtendedDate {
  private readonly date: Date

  static parseDateTime(string: string | null): Date | null {
    if (string === null) {
      return null
    }

    try {
      const timestamp = Date.parse(string)

      if (isNaN(timestamp)) {
        return null
      }

      return new Date(timestamp)
    } catch {
      return null
    }
  }

  static parseDate(string: string | null): Date | null {
    if (string === null) {
      return null
    }

    try {
      return new Date(
        Date.parse(string) +
        new Date(Date.parse(string)).getTimezoneOffset() * secondsInMinute * millisecondsInSecond
      )
    } catch {
      return null
    }
  }

  static isDatesEquals(dateOne: Date | null | undefined, dateTwo: Date | null | undefined): boolean {
    return isBlank(dateOne) && isBlank(dateTwo) ||
      isPresent(dateOne) && isPresent(dateTwo) && dateOne.getTime() === dateTwo.getTime()
  }

  constructor(date: Date) {
    this.date = date
  }

  serializeDate(): string {
    const year = this.getSerializedFullYear()
    const month = this.getSerializedMonth()
    const day = this.getSerializedMonthDay()
    return `${year}-${month}-${day}`
  }

  serializeDateTime(): string {
    const year = this.getSerializedFullYear()
    const month = this.getSerializedMonth()
    const day = this.getSerializedMonthDay()
    const hours = this.getSerializedHours()
    const minutes = this.getSerializedMinutes()
    const seconds = this.getSerializedSeconds()
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }

  formatDateTime(): string {
    const year = this.getSerializedFullYear()
    const month = this.getSerializedMonth()
    const day = this.getSerializedMonthDay()
    const hours = this.getSerializedHours()
    const minutes = this.getSerializedMinutes()
    const seconds = this.getSerializedSeconds()
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`
  }

  formatDate(): string {
    const year = this.getSerializedFullYear()
    const month = this.getSerializedMonth()
    const day = this.getSerializedMonthDay()
    return `${day}.${month}.${year}`
  }

  formatTime(): string {
    const hours = this.getSerializedHours()
    const minutes = this.getSerializedMinutes()
    const seconds = this.getSerializedSeconds()
    return `${hours}:${minutes}:${seconds}`
  }

  formatHoursMinutes(): string {
    const hours = this.getSerializedHours()
    const minutes = this.getSerializedMinutes()
    return `${hours}:${minutes}`
  }

  getSerializedFullYear(): string {
    return this.getFullYear().toString()
      .padStart(yearCharactersCount, "0")
  }

  getSerializedMonth(): string {
    return this.getMonth().toString()
      .padStart(monthCharactersCount, "0")
  }

  getSerializedMonthDay(): string {
    return this.getMonthDay().toString()
      .padStart(dayCharactersCount, "0")
  }

  getSerializedHours(): string {
    return this.getHours().toString()
      .padStart(hoursCharactersCount, "0")
  }

  getSerializedMinutes(): string {
    return this.getMinutes().toString()
      .padStart(minutesCharactersCount, "0")
  }

  getSerializedSeconds(): string {
    return this.getSeconds().toString()
      .padStart(secondsCharactersCount, "0")
  }

  getFullYear(): number {
    return this.date.getFullYear()
  }

  getMonth(): number {
    return this.date.getMonth() + 1
  }

  getMonthDay(): number {
    return this.date.getDate()
  }

  getHours(): number {
    return this.date.getHours()
  }

  getMinutes(): number {
    return this.date.getMinutes()
  }

  getSeconds(): number {
    return this.date.getSeconds()
  }

  getMonthDaysCount(): number {
    return new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate()
  }

  getFirstMonthDate(): Date {
    return new Date(this.date.getFullYear(), this.date.getMonth(), 1)
  }

  getLastMonthDate(): Date {
    return new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)
  }

  getStartOfHour(): Date {
    return new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), this.date.getHours())
  }

  getNextHour(): Date {
    const startOfHour: Date = this.getStartOfHour()
    return new Date(startOfHour.getTime() + millisecondsInHour)
  }

  isStartOfHour(): boolean {
    return this.getMinutes() === 0 && this.getSeconds() === 0
  }

  addDays(daysCount: number): Date {
    return new Date(this.date.getTime() + daysCount * millisecondsInDay)
  }

  addMonths(monthsCount: number): Date {
    return new Date(this.date.getFullYear(), this.date.getMonth() + monthsCount, 1)
  }

  isStartDate(): boolean {
    return this.getFullYear() === 1 && this.getMonth() === 1 && this.getMonthDay() === 1
  }
}
