import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import FuelCompaniesDomainDiModule from "./FuelCompaniesDomainDiModule"
import FuelCompaniesTableProvider from "../../presentation/table-providers/FuelCompaniesTableProvider"
import AppI18nDiModule from "../../../../core/di/modules/AppI18nDiModule"
import FuelCompanyFormProvider from "../../presentation/form-providers/FuelCompanyFormProvider"
import FuelCompanyInfoProvider from "../../presentation/info-providers/FuelCompanyInfoProvider"
import FuelCompaniesI18nDiModule from "./FuelCompaniesI18nDiModule"

const moduleKey = "fuelCompanies"

export default interface FuelCompaniesPresentationDiModule {
  provideFuelCompaniesListPage(): React.ReactNode

  provideFuelCompanyInfoPage(): React.ReactNode

  provideFuelCompanyFormPage(): React.ReactNode
}

export class DefaultFuelCompaniesPresentationDiModule
  extends DiModule
  implements FuelCompaniesPresentationDiModule {

  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly appI18nDiModule: AppI18nDiModule
  private readonly fuelCompaniesI18nDiModule: FuelCompaniesI18nDiModule
  private readonly fuelCompaniesDomainDiModule: FuelCompaniesDomainDiModule

  constructor(parameters: {
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly appI18nDiModule: AppI18nDiModule
    readonly fuelCompaniesI18nDiModule: FuelCompaniesI18nDiModule
    readonly fuelCompaniesDomainDiModule: FuelCompaniesDomainDiModule
  }) {
    super()
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.appI18nDiModule = parameters.appI18nDiModule
    this.fuelCompaniesI18nDiModule = parameters.fuelCompaniesI18nDiModule
    this.fuelCompaniesDomainDiModule = parameters.fuelCompaniesDomainDiModule
  }

  provideFuelCompaniesListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.fuelCompaniesDomainDiModule.provideGetFuelCompaniesUseCase(),
      tableProvider: this.provideFuelCompaniesTableProvider()
    })
  }

  private provideFuelCompaniesTableProvider(): FuelCompaniesTableProvider {
    return this.single(
      FuelCompaniesTableProvider.name,
      () => new FuelCompaniesTableProvider({
        fuelCompaniesI18: this.fuelCompaniesI18nDiModule.provideFuelCompaniesI18n()
      })
    )
  }

  provideFuelCompanyFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.fuelCompaniesDomainDiModule.provideGetFuelCompanyUseCase(),
      createObjectUseCase: this.fuelCompaniesDomainDiModule.provideCreateFuelCompanyUseCase(),
      updateObjectUseCase: this.fuelCompaniesDomainDiModule.provideUpdateFuelCompanyUseCase(),
      formProvider: this.provideFuelCompaniesFormProvider()
    })
  }

  private provideFuelCompaniesFormProvider(): FuelCompanyFormProvider {
    return this.single(
      FuelCompanyFormProvider.name,
      () => new FuelCompanyFormProvider({
        fuelCompaniesI18: this.fuelCompaniesI18nDiModule.provideFuelCompaniesI18n()
      })
    )
  }

  provideFuelCompanyInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.fuelCompaniesDomainDiModule.provideGetFuelCompanyUseCase(),
      infoProvider: this.provideFuelCompaniesInfoProvider()
    })
  }

  private provideFuelCompaniesInfoProvider(): FuelCompanyInfoProvider {
    return this.single(
      FuelCompanyInfoProvider.name,
      () => new FuelCompanyInfoProvider({
        fuelCompaniesI18: this.fuelCompaniesI18nDiModule.provideFuelCompaniesI18n()
      })
    )
  }
}
