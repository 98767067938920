import FuelCompaniesRepository from "../../domain/repositories/FuelCompaniesRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultFuelCompaniesRepository from "../../data/repositories/DefaultFuelCompaniesRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"

export default interface FuelCompaniesDataDiModule {
  provideFuelCompaniesRepository(): FuelCompaniesRepository
}

export class DefaultFuelCompaniesDataDiModule extends DiModule implements FuelCompaniesDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideFuelCompaniesRepository(): FuelCompaniesRepository {
    return this.single(
      DefaultFuelCompaniesRepository.name,
      () => new DefaultFuelCompaniesRepository({
        fuelCompaniesNetworkSource: this.appDataDiModule.provideFuelCompaniesNetworkSource()
      })
    )
  }
}
