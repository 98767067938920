import InfoProvider from "../../../../../admin/features/objects/presentation/providers/InfoProvider"
import InfoRow from "../../../../../admin/features/objects/presentation/entities/info/InfoRow"
import LinkInfo from "../../../../../admin/features/objects/presentation/entities/info/LinkInfo"
import StringInfoRow
  from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/StringInfoRow"
import AppUrlProvider from "../../../../core/presentation/services/AppUrlProvider"
import GasStationBreaksI18n from "../../i18n/GasStationBreaksI18n"
import FuelPrice from "../../../../core/domain/fuel-prices/FuelPrice"
import DateInfoRow from "../../../../../admin/features/objects/presentation/entities/info/info-row-by-type/DateInfoRow"
import DateTimeFormat from "../../../../../admin/features/objects/presentation/entities/shared/DateTimeFormat"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"
import GasStationBreak from "../../../../core/domain/gas-station-breaks/GasStationBreak"

export default class GasStationBreakInfoProvider implements InfoProvider<FuelPrice> {
  private readonly gasStationBreaksI18n: GasStationBreaksI18n
  private readonly timeZone: string

  constructor(parameters: {
    readonly gasStationBreaksI18n: GasStationBreaksI18n
    readonly timeZone: string
  }) {
    this.timeZone = parameters.timeZone
    this.gasStationBreaksI18n = parameters.gasStationBreaksI18n
  }

  getEntity(): string {
    return Entity.GAS_STATION_BREAKS
  }

  getObjectShortTitle(): string {
    return this.gasStationBreaksI18n.getTextProvider().existObjectTitle()
  }

  getObjectTitle(): string {
    return this.gasStationBreaksI18n.getTextProvider().existObjectTitle()
  }

  getInfoRows({ object }: {
    readonly object: GasStationBreak
  }): InfoRow[] {
    const gasStationBreaksTextProvider = this.gasStationBreaksI18n.getTextProvider()
    const appUrlProvider = new AppUrlProvider()

    return [
      new StringInfoRow({
        name: "gasStation",
        entity: Entity.GAS_STATIONS,
        title: gasStationBreaksTextProvider.gasStationNameField(),
        value: object?.gasStation?.name,
        url: object.gasStation && appUrlProvider.buildShowGasStationUrl({
          gasStationId: object.gasStation.id!
        })
      }),
      new DateInfoRow({
        name: "beginDateTime",
        title: gasStationBreaksTextProvider.beginDateTimeField(),
        value: object.beginDateTime,
        format: DateTimeFormat.DATE_TIME
      }),
      new DateInfoRow({
        name: "endDateTime",
        title: gasStationBreaksTextProvider.endDateTimeField(),
        value: object.endDateTime,
        format: DateTimeFormat.DATE_TIME
      }),
      new StringInfoRow({
        name: "comment",
        title: gasStationBreaksTextProvider.commentField(),
        value: object.comment
      })
    ]
  }

  getLinks(): LinkInfo[] {
    return []
  }

  getEditUrl({
    object
  }: {
    readonly object: GasStationBreak
  }): string {
    const urlProvider = new AppUrlProvider()
    return urlProvider.buildEditGasStationBreakUrl({
      gasStationBreakId: object.id!
    })
  }
}
