import GasStationBreaksRepository from "../../domain/repositories/GasStationBreaksRepository"
import DiModule from "../../../../../admin/lib/di/DiModule"
import DefaultGasStationBreaksRepository from "../../data/repositories/DefaultGasStationBreaksRepository"
import AppDataDiModule from "../../../../core/di/modules/AppDataDiModule"
import GasStationsForGasStationBreaksRepository from "../../domain/repositories/GasStationsForGasStationBreaksRepository"
import DefaultGasStationsForGasStationBreaksRepository
  from "../../data/repositories/DefaultGasStationsForGasStationBreaksRepository"

export default interface GasStationBreaksDataDiModule {
  provideGasStationBreaksRepository(): GasStationBreaksRepository

  provideGasStationsForGasStationBreaksRepository(): GasStationsForGasStationBreaksRepository
}

export class DefaultGasStationBreaksDataDiModule extends DiModule implements GasStationBreaksDataDiModule {
  private readonly appDataDiModule: AppDataDiModule

  constructor(parameters: {
    readonly appDataDiModule: AppDataDiModule
  }) {
    super()
    this.appDataDiModule = parameters.appDataDiModule
  }

  provideGasStationBreaksRepository(): GasStationBreaksRepository {
    return this.single(
      DefaultGasStationBreaksRepository.name,
      () => new DefaultGasStationBreaksRepository({
        gasStationBreaksNetworkSource: this.appDataDiModule.provideGasStationBreaksNetworkSource()
      })
    )
  }

  provideGasStationsForGasStationBreaksRepository(): GasStationsForGasStationBreaksRepository {
    return this.single(
      DefaultGasStationsForGasStationBreaksRepository.name,
      () => new DefaultGasStationsForGasStationBreaksRepository({
        gasStationsNetworkSource: this.appDataDiModule.provideGasStationsNetworkSource()
      })
    )
  }
}
