import NetworkFuelCompanyReconciliationReport
  from "../entities/fuel-company-reconciliation-reports/NetworkFuelCompanyReconciliationReport"
import FuelCompanyReconciliationReport
  from "../../domain/fuel-company-reconciliation-reports/FuelCompanyReconciliationReport"
import FuelCompaniesMapper from "./FuelCompaniesMapper"

export default class FuelCompanyReconciliationReportsMapper {
  mapNetworkToDomain({
    report
  }: {
    readonly report: NetworkFuelCompanyReconciliationReport
  }): FuelCompanyReconciliationReport {
    const {
      fuelCompany
    } = report

    return {
      id: report.id,
      createdAt: report.createdAt,
      fromDate: report.fromDate,
      toDate: report.toDate,
      fuelCompanyId: report.fuelCompanyId,
      fuelCompany: fuelCompany && new FuelCompaniesMapper().mapNetworkToDomain({ fuelCompany }),
      statusName: report.statusName,
      documentUrl: report.documentUrl,
      needRefresh: report.needRefresh
    }
  }

  mapDomainToNetwork({
    report
  }: {
    readonly report: FuelCompanyReconciliationReport
  }): NetworkFuelCompanyReconciliationReport {
    return {
      fromDate: report.fromDate,
      toDate: report.toDate,
      fuelCompanyId: report.fuelCompanyId
    }
  }
}
