import NetworkFuelPrice from "../entities/fuel-prices/NetworkFuelPrice"
import FuelPrice from "../../domain/fuel-prices/FuelPrice"
import FuelsMapper from "./FuelsMapper"
import GasStationsMapper from "./GasStationsMapper"

export default class FuelPricesMapper {
  mapNetworkToDomain({
    fuelPrice
  }: {
    readonly fuelPrice: NetworkFuelPrice
  }): FuelPrice {
    const { fuel, gasStation } = fuelPrice
    return {
      id: fuelPrice.id,
      value: fuelPrice.value,
      formattedValue: fuelPrice.formattedValue,
      startDate: fuelPrice.startDate,
      fuelId: fuelPrice.fuelId,
      fuel: fuel && new FuelsMapper().mapNetworkToDomain({ fuel }),
      gasStationId: fuelPrice.gasStationId,
      gasStation: gasStation && new GasStationsMapper().mapNetworkToDomain({ gasStation })
    }
  }

  mapDomainToNetwork({
    fuelPrice
  }: {
    readonly fuelPrice: FuelPrice
  }): NetworkFuelPrice {
    return {
      value: fuelPrice.value,
      startDate: fuelPrice.startDate,
      fuelId: fuelPrice.fuelId,
      gasStationId: fuelPrice.gasStationId
    }
  }
}
