import CurrentFuelCompanyBalancePresenter from "./CurrentFuelCompanyBalancePresenter"
import CurrentFuelCompanyBalancePage from "./CurrentFuelCompanyBalancePage"
import React from "react"
import useLocationContext from "../../../../../../admin/lib/presenter/useLocationContext"
import { useLocation, useSearchParams } from "react-router-dom"
import isBlank from "../../../../../../admin/lib/isBlank"
import addSearchParam from "../../../../../../admin/lib/addSearchParam"
import BalanceTransactionType from "../../../../../core/domain/balance-transactions/BalanceTransactionType"
import CurrentFuelCompanyBalancesI18n from "../../../i18n/CurrentFuelCompanyBalancesI18n"

const TRANSACTIONS_TYPE_KEY = "transactions_type"

interface Props {
  readonly currentFuelCompanyBalancesI18n: CurrentFuelCompanyBalancesI18n
  readonly providePresenter: (parameters: {
    readonly transactionsType?: BalanceTransactionType | null
  }) => CurrentFuelCompanyBalancePresenter
}

export default function CurrentFuelCompanyBalancePageRouteElement({
  providePresenter,
  currentFuelCompanyBalancesI18n
}: Props) {
  const locationContext = useLocationContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const transactionsType = parseBalanceTransactionType(searchParams.get(TRANSACTIONS_TYPE_KEY))

  function addTransactionsTypeToSearchParams(transactionsType: string) {
    let newSearchParams: URLSearchParams = searchParams

    newSearchParams = addSearchParam({
      searchParams: newSearchParams,
      key: TRANSACTIONS_TYPE_KEY,
      value: transactionsType
    })

    setSearchParams(newSearchParams, { replace: true, state: location.state })
  }

  function parseBalanceTransactionType(string: string | null): BalanceTransactionType | undefined {
    if (isBlank(string)) {
      return undefined
    }

    return string as BalanceTransactionType
  }

  return (
    <CurrentFuelCompanyBalancePage
      currentFuelCompanyBalancesI18n={currentFuelCompanyBalancesI18n}
      key={locationContext.locationId}
      cacheTransactionsType={addTransactionsTypeToSearchParams}
      providePresenter={() => {
        return locationContext.getOrCreatePresenter(() => {
          return providePresenter({
            transactionsType
          })
        })
      }}
    />
  )
}
