import NetworkInstallationInstance from "../entities/installation-instance/NetworkInstallationInstance"
import InstallationInstance from "../../domain/installation-instance/InstallationInstance"
import GasStationsMapper from "./GasStationsMapper"
import FuelCompaniesMapper from "./FuelCompaniesMapper"
import { InstallationInstanceTypeSelectOption } from "../../domain/installation-instance/InstallationInstanceType"

export default class InstallationInstancesMapper {
  mapNetworkToDomain({
    installationInstance,
    installationInstanceTypes
  }: {
    readonly installationInstance: NetworkInstallationInstance
    readonly installationInstanceTypes: InstallationInstanceTypeSelectOption[]
  }): InstallationInstance {
    const { gasStation } = installationInstance
    return {
      id: installationInstance.id,
      guid: installationInstance.guid,
      type: installationInstance.type,
      typeSelectOption: installationInstanceTypes.find((selectOptions) => {
        return selectOptions.id === installationInstance.type
      }),
      blocked: installationInstance.blocked,
      gasStationId: installationInstance.gasStationId,
      gasStation: gasStation && new GasStationsMapper().mapNetworkToDomain({ gasStation }),
      fuelCompanyId: installationInstance.gasStation?.fuelCompanyId,
      fuelCompany: gasStation && gasStation.fuelCompany && new FuelCompaniesMapper().mapNetworkToDomain({
        fuelCompany: gasStation.fuelCompany
      })
    }
  }

  mapDomainToNetwork({
    installationInstance
  }: {
    readonly installationInstance: InstallationInstance
  }): NetworkInstallationInstance {
    return {
      guid: installationInstance.guid,
      type: installationInstance.type,
      blocked: installationInstance.blocked,
      gasStationId: installationInstance.gasStationId
    }
  }
}
