import { Expose, Transform, Type } from "class-transformer"
import { Decimal } from "decimal.js"
import transformDecimal from "../../network/transformers/transformDecimal"
import NetworkFuel from "../fuels/NetworkFuel"
import NetworkGasStation from "../gas-stations/NetworkGasStation"
import transformDateWithoutTime from "../../network/transformers/transformDateWithoutTime"

export default class NetworkFuelPrice {

  @Expose()
  @Type(() => Number)
  readonly id?: number | null

  @Expose()
  @Transform(transformDecimal)
  readonly value?: Decimal | null

  @Expose()
  @Type(() => String)
  readonly formattedValue?: string | null

  @Expose()
  @Transform(transformDateWithoutTime)
  readonly startDate?: Date | null

  @Expose()
  @Type(() => Number)
  readonly fuelId?: number | null

  @Expose()
  @Type(() => NetworkFuel)
  readonly fuel?: NetworkFuel | null

  @Expose()
  @Type(() => Number)
  readonly gasStationId?: number | null

  @Expose()
  @Type(() => NetworkGasStation)
  readonly gasStation?: NetworkGasStation | null
}
