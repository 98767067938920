import NetworkCarrierCommercialTerm from "../entities/carrier-commercial-terms/NetworkCarrierCommercialTerm"
import CarrierCommercialTerm from "../../domain/carrier-commercial-terms/CarrierCommercialTerm"
import CarriersMapper from "./CarriersMapper"
import FuelCompaniesMapper from "./FuelCompaniesMapper"
import FuelsMapper from "./FuelsMapper"
import GasStationsMapper from "./GasStationsMapper"
import { PricingTypeSelectOption } from "../../domain/commercial-terms/PricingType"

export default class CarrierCommercialTermsMapper {
  mapNetworkToDomain({
    carrierCommercialTerm,
    pricingTypeSelectOptions
  }: {
    readonly carrierCommercialTerm: NetworkCarrierCommercialTerm
    readonly pricingTypeSelectOptions: PricingTypeSelectOption[]
  }): CarrierCommercialTerm {
    const {
      carrier,
      fuelCompany,
      gasStations,
      fuel
    } = carrierCommercialTerm

    const gasStationsMapper = new GasStationsMapper()

    return {
      id: carrierCommercialTerm.id,
      carrierId: carrierCommercialTerm.carrierId,
      carrier: carrier && new CarriersMapper().mapNetworkToDomain({ carrier }),
      fuelCompanyId: carrierCommercialTerm.fuelCompanyId,
      fuelCompany: fuelCompany && new FuelCompaniesMapper().mapNetworkToDomain({ fuelCompany }),
      fuelId: carrierCommercialTerm.fuelId,
      fuel: fuel && new FuelsMapper().mapNetworkToDomain({ fuel }),
      gasStationIds: carrierCommercialTerm.gasStationIds,
      gasStations: gasStations && gasStations.map((gasStation) => {
        return gasStationsMapper.mapNetworkToDomain({ gasStation })
      }),
      beginDate: carrierCommercialTerm.beginDate,
      endDate: carrierCommercialTerm.endDate,
      pricingType: carrierCommercialTerm.pricingType,
      pricingTypeSelectOption: pricingTypeSelectOptions.find((option) => {
        return option.id === carrierCommercialTerm.pricingType
      }),
      price: carrierCommercialTerm.price,
      formattedPrice: carrierCommercialTerm.formattedPrice,
      discountPercentage: carrierCommercialTerm.discountPercentage,
      formattedDiscountPercentage: carrierCommercialTerm.formattedDiscountPercentage,
      documentUrl: carrierCommercialTerm.documentUrl
    }
  }

  mapDomainToNetwork({
    carrierCommercialTerm
  }: {
    readonly carrierCommercialTerm: CarrierCommercialTerm
  }): NetworkCarrierCommercialTerm {
    return {
      carrierId: carrierCommercialTerm.carrierId,
      fuelCompanyId: carrierCommercialTerm.fuelCompanyId,
      fuelId: carrierCommercialTerm.fuelId,
      gasStationIds: carrierCommercialTerm.gasStationIds,
      beginDate: carrierCommercialTerm.beginDate,
      endDate: carrierCommercialTerm.endDate,
      pricingType: carrierCommercialTerm.pricingType,
      price: carrierCommercialTerm.price,
      discountPercentage: carrierCommercialTerm.discountPercentage,
      documentUrl: carrierCommercialTerm.documentUrl
    }
  }
}
