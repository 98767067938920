import FuelCompaniesRepository from "../../domain/repositories/FuelCompaniesRepository"
import FuelCompaniesNetworkSource from "../../../../core/data/sources/network/FuelCompaniesNetworkSource"
import { GetFuelCompaniesParameters } from "../../domain/use-cases/fuel-companies/GetFuelCompaniesUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import NetworkFuelCompaniesRequestFilter
  from "../../../../core/data/entities/fuel-companies/request-queries/NetworkFuelCompaniesRequestFilter"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateFuelCompanyParameters } from "../../domain/use-cases/fuel-companies/CreateFuelCompanyUseCase"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetFuelCompanyParameters } from "../../domain/use-cases/fuel-companies/GetFuelCompanyUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateFuelCompanyParameters } from "../../domain/use-cases/fuel-companies/UpdateFuelCompanyUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompaniesMapper from "../../../../core/data/mappers/FuelCompaniesMapper"
import FuelCompanyError from "../../../../core/domain/fuel-companies/FuelCompanyError"

export default class DefaultFuelCompaniesRepository implements FuelCompaniesRepository {
  private readonly fuelCompaniesNetworkSource: FuelCompaniesNetworkSource

  constructor(parameters: {
    readonly fuelCompaniesNetworkSource: FuelCompaniesNetworkSource
  }) {
    this.fuelCompaniesNetworkSource = parameters.fuelCompaniesNetworkSource
  }

  async getFuelCompanies({
    query,
    pagination,
    sort
  }: GetFuelCompaniesParameters): Promise<GetObjectsResult<FuelCompany>> {
    const result = await this.fuelCompaniesNetworkSource.getFuelCompanies({
      filter: new NetworkFuelCompaniesRequestFilter({
        query
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    const fuelCompaniesMapper = new FuelCompaniesMapper()
    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.fuelCompanies!.map((fuelCompany) => {
              return fuelCompaniesMapper.mapNetworkToDomain({ fuelCompany })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getFuelCompany({
    objectId
  }: GetFuelCompanyParameters): Promise<GetObjectResult<FuelCompany>> {
    const mapper = new FuelCompaniesMapper()
    const result = await this.fuelCompaniesNetworkSource.getFuelCompany({
      fuelCompanyId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            fuelCompany: result.data.fuelCompany!
          })
        }
      default:
        return result
    }
  }

  async createFuelCompany({
    object: fuelCompany
  }: CreateFuelCompanyParameters): Promise<CreateObjectResult<FuelCompany, FuelCompanyError>> {
    const mapper = new FuelCompaniesMapper()
    const result = await this.fuelCompaniesNetworkSource.createFuelCompany({
      fuelCompany: mapper.mapDomainToNetwork({ fuelCompany })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            fuelCompany: result.data.fuelCompany!
          })
        }
      default:
        return result
    }
  }

  async updateFuelCompany({
    objectId: fuelCompanyId,
    object: fuelCompany
  }: UpdateFuelCompanyParameters): Promise<UpdateObjectResult<FuelCompany, FuelCompanyError>> {
    const mapper = new FuelCompaniesMapper()
    const result = await this.fuelCompaniesNetworkSource.updateFuelCompany({
      fuelCompanyId: parseInt(fuelCompanyId),
      fuelCompany: mapper.mapDomainToNetwork({
        fuelCompany
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            fuelCompany: result.data.fuelCompany!
          })
        }
      default:
        return result
    }
  }
}
