import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import { useCoreTextProvider } from "../../../../../core/presentation/contexts/CoreTextProviderContext"
import { useCoreThemeProvider } from "../../../../../core/presentation/contexts/CoreThemeProviderContext"
import React, { ReactElement } from "react"
import styles from "./TableComponent.module.scss"
import Table from "../../entities/tables/Table"
import TableColumn, { TableColumnAlignment } from "../../entities/tables/TableColumn"
import ExceptionLocalizer from "../../../../../core/presentation/services/ExceptionLocalizer"
import TableValue from "../../entities/tables/TableValue"
import TextTableValue from "../../entities/tables/table-value-by-type/TextTableValue"
import TextTableValueComponent from "../table-value-by-type/text-table-value/TextTableValueComponent"
import NumberTableValue from "../../entities/tables/table-value-by-type/NumberTableValue"
import NumberTableValueComponent from "../table-value-by-type/number-table-value/NumberTableValueComponent"
import DecimalTableValue from "../../entities/tables/table-value-by-type/DecimalTableValue"
import DecimalTableValueComponent from "../table-value-by-type/decimal-table-value/DecimalTableValueComponent"
import isPresent from "../../../../../lib/isPresent"
import { Link } from "react-router-dom"
import Sort from "../../entities/tables/Sort"
import { TableColumnSortingType } from "../../entities/tables/TableColumnSortingType"
import PaginationComponent from "../../../../../core/presentation/components/pagination/PaginationComponent"
import LoadingIndicatorComponent from "../../../../../lib/loading-indicator/LoadingIndicatorComponent"
import DateTableValue from "../../entities/tables/table-value-by-type/DateTableValue"
import DateTableValueComponent from "../table-value-by-type/date-table-value/DateTableValueComponent"
import BooleanTableValue from "../../entities/tables/table-value-by-type/BooleanTableValue"
import BooleanTableValueComponent from "../table-value-by-type/boolean-table-value/BooleanTableValueComponent"
import ArrayTableValue from "../../entities/tables/table-value-by-type/ArrayTableValue"
import ArrayTableValueComponent from "../table-value-by-type/array-table-value/ArrayTableValueComponent"
import ExecutionError from "../../../../../core/domain/entities/errors/ExecutionError"
import ApplicationException from "../../../../../core/domain/exceptions/ApplicationException"
import isBlank from "../../../../../lib/isBlank"

export default function TableComponent<DomainObject, FilterObject = never>({
  className = "",
  table,
  sort,
  onColumnClicked = () => undefined,
  isLoading,
  objects,
  error,
  exception,
  onNextPageRequested,
  onRetryLoadClicked
}: {
  readonly className?: string
  readonly table: Table<DomainObject, FilterObject>
  readonly sort?: Sort
  readonly onColumnClicked?: (columnName: string) => void
  readonly isLoading: boolean
  readonly objects: DomainObject[],
  readonly error?: ExecutionError
  readonly exception?: ApplicationException
  readonly onNextPageRequested: () => void
  readonly onRetryLoadClicked: () => void
}) {
  const coreTextProvider: CoreTextProvider = useCoreTextProvider()
  const theme = useCoreThemeProvider()
  const columns: TableColumn<DomainObject>[] = table.getColumns()

  const sortingImage = ({
    column,
    sort
  }: {
    readonly column: TableColumn<DomainObject>,
    readonly sort?: Sort
  }) => {
    if (column.getName() === sort?.id) {
      return ((): ReactElement => {
        switch (sort?.type) {
          case TableColumnSortingType.ASC:
            return theme.icArrowDownOutlinedActiveComponent()
          case TableColumnSortingType.DESC:
            return theme.icArrowUpOutlinedActiveComponent()
          default:
            return theme.icArrowsUpDownOutlinedDefaultComponent()
        }
      })()
    }

    return theme.icArrowsUpDownOutlinedDefaultComponent()
  }

  const alignmentClass = (column: TableColumn<DomainObject>): string | undefined => {
    switch (column.getColumnAlignment()) {
      case TableColumnAlignment.RIGHT:
        return styles.rightAlignment
      default:
        return undefined
    }
  }

  const tableHeader = (
    <tr>
      {columns
        .filter((column) => {
          return column.getVisible() === true
        })
        .map((column: TableColumn<DomainObject>) => (
          <th
            onClick={() => {
              column.getSortingAvailable() &&
              onColumnClicked(column.getName())
            }}
            className={column.getSortingAvailable() ? styles.clickable : undefined}
            key={column.getName()}
          >
            <div className={`${styles.thContent} ${alignmentClass(column)} ${theme.tableHeader1PrimaryClassName()}`}>
              {column.getTitle()}
              {column.getSortingAvailable() && sortingImage({ column, sort })}
            </div>
          </th>
        ))}
    </tr>
  )

  const objectRows = (() => {
    return (
      <>
        {objects.length === 0 && !isLoading && isBlank(error) && isBlank(exception) && (
          <tr className={styles.emptyStateRow}>
            <td colSpan={columns.length}>
              <div className={`${styles.messageContainer} ${theme.tableMessageContainerClassName()}`}>
                <div className={theme.tableRow2ErrorClassName()}>
                  {coreTextProvider.noObjects()}
                </div>
              </div>
            </td>
          </tr>
        )}
        {
          objects.map((object: DomainObject) => {
            const rowUrl: string | null | undefined = table.getRowUrl(object)
            const canShowObject = table.getCanShowObject() === true
            const isRowLinkAvailable = isPresent(rowUrl) && canShowObject

            return (
              <tr
                className={theme.tableRow1PrimaryClassName()}
                key={table.getObjectId(object)}
              >
                {columns
                  .filter((column) => {
                    return column.getVisible() === true
                  })
                  .map((column: TableColumn<DomainObject>) => {
                    const tableValue: TableValue | null = column.createValue(object)
                    const cellUrl: string | null | undefined = tableValue?.getUrl()
                    const canShowUrl = column.isUrlVisibleByPermission() === true
                    const isUrlVisible = isPresent(cellUrl) && canShowUrl

                    const cellContent: React.ReactNode = (() => {
                      if (tableValue === null) {
                        return <></>
                      }

                      if (tableValue instanceof TextTableValue) {
                        return (
                          <TextTableValueComponent
                            textTableValue={tableValue}
                            column={column}
                          />
                        )
                      }

                      if (tableValue instanceof NumberTableValue) {
                        return <NumberTableValueComponent numberTableValue={tableValue} />
                      }

                      if (tableValue instanceof DecimalTableValue) {
                        return <DecimalTableValueComponent decimalTableValue={tableValue} />
                      }

                      if (tableValue instanceof DateTableValue) {
                        return <DateTableValueComponent dateTableValue={tableValue} />
                      }

                      if (tableValue instanceof BooleanTableValue) {
                        return <BooleanTableValueComponent booleanTableValue={tableValue} />
                      }

                      if (tableValue instanceof ArrayTableValue) {
                        return (
                          <ArrayTableValueComponent
                            arrayTableValue={tableValue}
                            column={column}
                          />
                        )
                      }

                      throw "Unknown table value type"
                    })()

                    const openInNewTab = tableValue?.getOpenInNewTab() ?? false
                    return (
                      <td key={column.getName()}>
                        <div className={`${styles.tdContent} ${alignmentClass(column)}`}>
                          {isRowLinkAvailable && <Link to={rowUrl} className={styles.rowLink} />}
                          {isUrlVisible ?
                            <Link to={cellUrl} className={styles.cellLink} target={openInNewTab ? "_blank" : undefined}>{cellContent}</Link> :
                            cellContent}
                        </div>
                      </td>
                    )
                  })}
              </tr>
            )
          })
        }
      </>
    )
  })()

  const loadingIndicator = (() => {
    if (!isLoading) return <></>

    return (
      <tr className={styles.loadingIndicatorRow}>
        <td colSpan={columns.length}>
          <LoadingIndicatorComponent>
            {theme.icLoadingIndicatorComponent()}
          </LoadingIndicatorComponent>
        </td>
      </tr>
    )
  })()

  const errorMessage = (() => {
    const message: string | null | undefined = (() => {
      if (isPresent(error)) {
        return error.message
      }

      if (isPresent(exception)) {
        return new ExceptionLocalizer({ coreTextProvider })
          .localizeException(exception)
      }

      return undefined
    })()

    return (
      <>
        {message && (
          <tr className={styles.errorMessageRow}>
            <td colSpan={columns.length}>
              <div
                className={`${styles.messageContainer} ${theme.tableMessageContainerClassName()}`}
                onClick={onRetryLoadClicked}
              >
                <div className={theme.tableRow2ErrorClassName()}>
                  {message}
                </div>
                <div className={theme.tableRow2LinkClassName()}>
                  {coreTextProvider.retry()}
                </div>
              </div>
            </td>
          </tr>
        )}
      </>
    )
  })()

  const description = (() => {
    return (
      <>
        {table.getDescription() && (
          <div
            className={`${styles.descriptionContainer} ${theme.tableRow1PrimaryClassName()}`}
          >
            {table.getDescription()}
          </div>
        )}
      </>
    )
  })()

  return (
    <div className={`${styles.root} ${className}`}>
      <table className={`${styles.table} ${theme.tableClassName()}`}>
        <thead>
          {tableHeader}
        </thead>
        <tbody>
          {objectRows}
          {loadingIndicator}
          {errorMessage}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={columns.length}>
              {!isLoading && isBlank(error) && isBlank(exception) && (
                <PaginationComponent onComponentAppeared={onNextPageRequested} />
              )}
            </td>
          </tr>
        </tfoot>
      </table>
      {description}
    </div>
  )
}
