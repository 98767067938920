import FuelPricesRepository from "../../domain/repositories/FuelPricesRepository"
import { GetFuelPricesParameters } from "../../domain/use-cases/fuel-prices/GetFuelPricesUseCase"
import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import { CreateFuelPriceParameters } from "../../domain/use-cases/fuel-prices/CreateFuelPriceUseCase"
import { CreateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/CreateObjectUseCase"
import { GetFuelPriceParameters } from "../../domain/use-cases/fuel-prices/GetFuelPriceUseCase"
import { GetObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectUseCase"
import { UpdateFuelPriceParameters } from "../../domain/use-cases/fuel-prices/UpdateFuelPriceUseCase"
import { UpdateObjectResult } from "../../../../../admin/features/objects/domain/use-cases/objects/UpdateObjectUseCase"
import FuelPricesNetworkSource from "../../../../core/data/sources/network/FuelPricesNetworkSource"
import FuelPrice from "../../../../core/domain/fuel-prices/FuelPrice"
import NetworkFuelPricesRequestFilter
  from "../../../../core/data/entities/fuel-prices/request-queries/NetworkFuelPricesRequestFilter"
import FuelPricesMapper from "../../../../core/data/mappers/FuelPricesMapper"
import FuelPriceError from "../../../../core/domain/fuel-prices/FuelPriceError"
import isPresent from "../../../../../admin/lib/isPresent"

export default class DefaultFuelPricesRepository implements FuelPricesRepository {
  private readonly fuelPricesNetworkSource: FuelPricesNetworkSource

  constructor(parameters: {
    readonly fuelPricesNetworkSource: FuelPricesNetworkSource
  }) {
    this.fuelPricesNetworkSource = parameters.fuelPricesNetworkSource
  }

  async getFuelPrices({
    filter,
    query,
    pagination,
    sort
  }: GetFuelPricesParameters): Promise<GetObjectsResult<FuelPrice>> {
    const gasStationId = filter?.gasStationId
    const fuelId = filter?.fuelId
    const result = await this.fuelPricesNetworkSource.getFuelPrices({
      filter: new NetworkFuelPricesRequestFilter({
        query,
        gasStationIds: isPresent(gasStationId) ? [gasStationId] : null,
        fuelIds: isPresent(fuelId) ? [fuelId] : null
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.fuelPrices!.map((fuelPrice) => {
              return new FuelPricesMapper().mapNetworkToDomain({ fuelPrice })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }

  async getFuelPrice({
    objectId
  }: GetFuelPriceParameters): Promise<GetObjectResult<FuelPrice>> {
    const result = await this.fuelPricesNetworkSource.getFuelPrice({
      fuelPriceId: parseInt(objectId)
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: new FuelPricesMapper().mapNetworkToDomain({
            fuelPrice: result.data.fuelPrice!
          })
        }
      default:
        return result
    }
  }

  async createFuelPrice({
    object: fuelPrice
  }: CreateFuelPriceParameters): Promise<CreateObjectResult<FuelPrice, FuelPriceError>> {
    const mapper = new FuelPricesMapper()
    const result = await this.fuelPricesNetworkSource.createFuelPrice({
      fuelPrice: mapper.mapDomainToNetwork({ fuelPrice })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            fuelPrice: result.data.fuelPrice!
          })
        }
      default:
        return result
    }
  }

  async updateFuelPrice({
    objectId: fuelPriceId,
    object: fuelPrice
  }: UpdateFuelPriceParameters): Promise<UpdateObjectResult<FuelPrice, FuelPriceError>> {
    const mapper = new FuelPricesMapper()
    const result = await this.fuelPricesNetworkSource.updateFuelPrice({
      fuelPriceId: parseInt(fuelPriceId),
      fuelPrice: mapper.mapDomainToNetwork({
        fuelPrice
      })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: mapper.mapNetworkToDomain({
            fuelPrice: result.data.fuelPrice!
          })
        }
      default:
        return result
    }
  }
}
