import AppTextProvider from "../AppTextProvider"
import User from "../../domain/users/User"
import isBlank from "../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../admin/lib/filterNotEmpty"
import isPresent from "../../../../admin/lib/isPresent"

export default class DefaultRuAppTextProvider implements AppTextProvider {

  pricingTypeFixedText(): string {
    return "Фиксированная цена"
  }

  pricingTypeDiscountText(): string {
    return "От цены на стеле"
  }

  refuellingOrderStatusCancelledText(): string {
    return "Отменена заправщиком"
  }

  refuellingOrderStatusClaimText(): string {
    return "Претензия"
  }

  refuellingOrderStatusDriverConfirmationPendingText(): string {
    return "Ожидание подтверждения водителя"
  }

  refuellingOrderStatusFinishedText(): string {
    return "Успешно завершена"
  }

  refuellingOrderStatusGasStationConfirmationPendingText(): string {
    return "Создание"
  }

  refuellingOrderStatusGasStationCorrectionPendingText(): string {
    return "На пересмотре"
  }

  refuellingOrderStatusNotConfirmedByGasStationText(): string {
    return "Не состоялась"
  }

  refuellingOrderStatusRefuellingPendingText(): string {
    return "Ожидание старта"
  }

  refuellingOrderStatusRefuellingText(): string {
    return "Идет заправка"
  }

  tripStatusPendingText(): string {
    return "Создан"
  }

  tripStatusConfirmedText(): string {
    return "Подтвержден"
  }

  tripStatusFinishedText(): string {
    return "Завершен"
  }

  tripStatusCanceledText(): string {
    return "Отменен"
  }

  userTripStatusPendingText(): string {
    return "Ожидает подтверждения"
  }

  userTripStatusConfirmedText(): string {
    return "Подтвержден"
  }

  userTripStatusFinishedText(): string {
    return "Завершен"
  }

  userTripStatusCanceledText(): string {
    return "Отменен"
  }

  tripLimitTargetTypeRefuellingByMoneyText(): string {
    return "На разовую заправку, в рублях"
  }

  tripLimitTargetTypeRefuellingByFuelText(): string {
    return "На разовую заправку, в объеме топлива"
  }

  tripLimitTargetTypeTripByMoneyText(): string {
    return "На рейс, в рублях"
  }

  tripLimitTargetTypeTripByFuelText(): string {
    return "На рейс, в объеме топлива"
  }

  tripLimitTargetTypeDailyByMoneyText(): string {
    return "На сутки, в рублях"
  }

  tripLimitTargetTypeDailyByFuelTypeText(): string {
    return "На сутки, в объеме топлива"
  }

  driverName({
    user
  }: {
    readonly user: User | null | undefined
  }): string {
    if (isBlank(user)) return ""

    const fullName = filterNotEmpty([
      user.profile?.lastName,
      user.profile?.firstName,
      user.profile?.middleName
    ]).join(" ")

    if (isPresent(fullName)) return fullName

    return `Водитель №${user.id!}`
  }

  userName({
    user
  }: {
    readonly user: User | null | undefined
  }): string {
    if (isBlank(user)) return ""

    const fullName = filterNotEmpty([
      user.profile?.lastName,
      user.profile?.firstName,
      user.profile?.middleName
    ]).join(" ")

    if (isPresent(fullName)) return fullName

    return `Пользователь №${user.id!}`
  }

  changingTypeCreatedText(): string {
    return "Создание"
  }

  changingTypeUpdatedText(): string {
    return "Обновление"
  }

  changingTypeDeletedText(): string {
    return "Удаление"
  }

  installationInstanceTypeMethanikaRefuellerText(): string {
    return "Android-устройство"
  }

  installationInstanceTypeShelfGasStationText(): string {
    return "SHELF GAS STATION"
  }

  installationInstanceTypeServioPumpGasText(): string {
    return "SERVIOPUMP GAS"
  }

  installationInstanceTypeRodnik2Text(): string {
    return "Родник-2"
  }

  installationInstanceTypeSnCardText(): string {
    return "Сибнефтекарт"
  }

  installationInstanceTypeTopazText(): string {
    return "Топаз-АЗС"
  }
}
