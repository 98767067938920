import React from "react"
import DiModule from "../../../../../admin/lib/di/DiModule"
import ObjectsPresentationDiModule from "../../../../../admin/features/objects/di/modules/ObjectsPresentationDiModule"
import FuelPricesDomainDiModule from "./FuelPricesDomainDiModule"
import FuelPricesTableProvider from "../../presentation/table-providers/FuelPricesTableProvider"
import FuelPriceFormProvider from "../../presentation/form-providers/FuelPriceFormProvider"
import FuelPriceInfoProvider from "../../presentation/info-providers/FuelPriceInfoProvider"
import FuelPricesI18nDiModule from "./FuelPricesI18nDiModule"
import CoreI18nDiModule from "../../../../../admin/core/di/modules/CoreI18nDiModule"

const moduleKey = "fuelPrices"

export default interface FuelPricesPresentationDiModule {
  provideFuelPricesListPage(): React.ReactNode

  provideFuelPriceInfoPage(): React.ReactNode

  provideFuelPriceFormPage(): React.ReactNode
}

export class DefaultFuelPricesPresentationDiModule
  extends DiModule
  implements FuelPricesPresentationDiModule {

  private readonly timeZone: string
  private readonly coreI18nDiModule: CoreI18nDiModule
  private readonly objectsPresentationDiModule: ObjectsPresentationDiModule
  private readonly fuelPricesI18nDiModule: FuelPricesI18nDiModule
  private readonly fuelPricesDomainDiModule: FuelPricesDomainDiModule

  constructor(parameters: {
    readonly timeZone: string
    readonly coreI18nDiModule: CoreI18nDiModule
    readonly objectsPresentationDiModule: ObjectsPresentationDiModule
    readonly fuelPricesI18nDiModule: FuelPricesI18nDiModule
    readonly fuelPricesDomainDiModule: FuelPricesDomainDiModule
  }) {
    super()
    this.timeZone = parameters.timeZone
    this.coreI18nDiModule = parameters.coreI18nDiModule
    this.objectsPresentationDiModule = parameters.objectsPresentationDiModule
    this.fuelPricesI18nDiModule = parameters.fuelPricesI18nDiModule
    this.fuelPricesDomainDiModule = parameters.fuelPricesDomainDiModule
  }

  provideFuelPricesListPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectsListPage({
      key: moduleKey,
      getObjectsUseCase: this.fuelPricesDomainDiModule.provideGetFuelPricesUseCase(),
      tableProvider: this.provideFuelPricesTableProvider()
    })
  }

  private provideFuelPricesTableProvider(): FuelPricesTableProvider {
    return this.single(
      FuelPricesTableProvider.name,
      () => new FuelPricesTableProvider({
        coreI18n: this.coreI18nDiModule.provideCoreI18n(),
        fuelPricesI18n: this.fuelPricesI18nDiModule.provideFuelPricesI18n(),
        getGasStationsUseCase: this.fuelPricesDomainDiModule.provideGetGasStationsForFuelPriceUseCase(),
        getFuelsForFuelPriceUseCase: this.fuelPricesDomainDiModule.provideGetFuelsForFuelPriceUseCase()
      })
    )
  }

  provideFuelPriceFormPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectFormPage({
      key: moduleKey,
      getObjectUseCase: this.fuelPricesDomainDiModule.provideGetFuelPriceUseCase(),
      createObjectUseCase: this.fuelPricesDomainDiModule.provideCreateFuelPriceUseCase(),
      updateObjectUseCase: this.fuelPricesDomainDiModule.provideUpdateFuelPriceUseCase(),
      formProvider: this.provideFuelPriceFormProvider()
    })
  }

  private provideFuelPriceFormProvider(): FuelPriceFormProvider {
    return this.single(
      FuelPriceFormProvider.name,
      () => new FuelPriceFormProvider({
        timeZone: this.timeZone,
        fuelPricesI18: this.fuelPricesI18nDiModule.provideFuelPricesI18n(),
        getGasStationsForFuelPriceUseCase: this.fuelPricesDomainDiModule.provideGetGasStationsForFuelPriceUseCase(),
        getFuelsForFuelPriceUseCase: this.fuelPricesDomainDiModule.provideGetFuelsForFuelPriceUseCase()
      })
    )
  }

  provideFuelPriceInfoPage(): React.ReactNode {
    return this.objectsPresentationDiModule.provideObjectInfoPage({
      key: moduleKey,
      getObjectUseCase: this.fuelPricesDomainDiModule.provideGetFuelPriceUseCase(),
      infoProvider: this.provideFuelPriceInfoProvider()
    })
  }

  private provideFuelPriceInfoProvider(): FuelPriceInfoProvider {
    return this.single(
      FuelPriceInfoProvider.name,
      () => new FuelPriceInfoProvider({
        timeZone: this.timeZone,
        fuelPricesI18n: this.fuelPricesI18nDiModule.provideFuelPricesI18n()
      })
    )
  }
}
