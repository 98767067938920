import { GetObjectsResult } from "../../../../../admin/features/objects/domain/use-cases/objects/GetObjectsUseCase"
import PagesMapper from "../../../../core/data/mappers/PagesMapper"
import LastItemPaginationsMapper from "../../../../core/data/mappers/LastItemPaginationsMapper"
import SortMapper from "../../../../core/data/mappers/SortMapper"
import FuelCompaniesForFuelCompanyReconciliationReportsRepository
  from "../../domain/repositories/FuelCompaniesForFuelCompanyReconciliationReportsRepository"
import FuelCompaniesNetworkSource from "../../../../core/data/sources/network/FuelCompaniesNetworkSource"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import NetworkFuelCompaniesRequestFilter
  from "../../../../core/data/entities/fuel-companies/request-queries/NetworkFuelCompaniesRequestFilter"
import FuelCompaniesMapper from "../../../../core/data/mappers/FuelCompaniesMapper"
import {
  GetFuelCompaniesForFuelCompanyReconciliationReportsParameters
} from "../../domain/use-cases/fuel-company/GetFuelCompaniesForFuelCompanyReconciliationReportsUseCase"

export default class DefaultFuelCompaniesForFuelCompanyReconciliationReportsRepository
  implements FuelCompaniesForFuelCompanyReconciliationReportsRepository {
  private readonly fuelCompaniesNetworkSource: FuelCompaniesNetworkSource

  constructor(parameters: {
    readonly fuelCompaniesNetworkSource: FuelCompaniesNetworkSource
  }) {
    this.fuelCompaniesNetworkSource = parameters.fuelCompaniesNetworkSource
  }

  async getFuelCompanies({
    ids,
    query,
    pagination,
    sort
  }: GetFuelCompaniesForFuelCompanyReconciliationReportsParameters): Promise<GetObjectsResult<FuelCompany>> {
    const result = await this.fuelCompaniesNetworkSource.getFuelCompanies({
      filter: new NetworkFuelCompaniesRequestFilter({
        query,
        ids
      }),
      pagination: pagination && new LastItemPaginationsMapper().mapDomainToNetwork({
        pagination
      }),
      sort: sort && new SortMapper().mapDomainToNetwork({ sort })
    })

    switch (result.type) {
      case "success":
        return {
          type: "success",
          data: {
            objects: result.data.fuelCompanies!.map((fuelCompany) => {
              return new FuelCompaniesMapper().mapNetworkToDomain({ fuelCompany })
            }),
            page: new PagesMapper().mapNetworkToDomain({
              page: result.data.page!
            })
          }
        }
      default:
        return result
    }
  }
}
