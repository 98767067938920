import FormProvider from "../../../../../admin/features/objects/presentation/providers/FormProvider"
import FormField from "../../../../../admin/features/objects/presentation/entities/forms/FormField"
import StringFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/StringFormField"
import FormFieldGroup from "../../../../../admin/features/objects/presentation/entities/forms/FormFieldGroup"
import isBlank from "../../../../../admin/lib/isBlank"
import { filterNotEmpty } from "../../../../../admin/lib/filterNotEmpty"
import isPresent from "../../../../../admin/lib/isPresent"
import BooleanFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/BooleanFormField"
import FuelCompaniesI18 from "../../i18n/FuelCompaniesI18"
import FuelCompany from "../../../../core/domain/fuel-companies/FuelCompany"
import FuelCompanyError from "../../../../core/domain/fuel-companies/FuelCompanyError"
import FuelCompanyErrorsObject from "../../../../core/domain/fuel-companies/FuelCompanyErrorsObject"
import PhoneNumberFormField
  from "../../../../../admin/features/objects/presentation/entities/forms/form-field-by-type/PhoneNumberFormField"
import { Entity } from "../../../../../admin/core/domain/entities/user-profile/Entity"

const mainGroupName = "main"
const legalEntityGroupName = "legalEntity"
const contactPersonGroupName = "contactPerson"

export default class FuelCompanyFormProvider
  implements FormProvider<FuelCompany, FuelCompanyError, FuelCompanyErrorsObject> {
  private readonly fuelCompaniesI18: FuelCompaniesI18

  constructor(parameters: {
    readonly fuelCompaniesI18: FuelCompaniesI18
  }) {
    this.fuelCompaniesI18 = parameters.fuelCompaniesI18
  }

  getEntity(): string {
    return Entity.FUEL_COMPANIES
  }

  getNewObjectTitle(): string {
    return this.fuelCompaniesI18.getTextProvider().newObjectTitle()
  }

  getExistedObjectShortTitle({
    object
  }: {
    readonly object?: FuelCompany
  }): string {
    if (isBlank(object)) return this.fuelCompaniesI18.getTextProvider().existObjectTitle()

    const { name } = object

    return isPresent(name) ? name : this.fuelCompaniesI18.getTextProvider().existObjectTitle()
  }

  getExistedObjectTitle({
    object
  }: {
    readonly object?: FuelCompany
  }): string {
    if (isBlank(object)) return this.fuelCompaniesI18.getTextProvider().existObjectTitle()

    const { name } = object

    const components = filterNotEmpty([
      name
    ])

    return components.length > 0 ?
      components.join(" ") :
      this.fuelCompaniesI18.getTextProvider().existObjectTitle()
  }

  async buildObject(): Promise<FuelCompany> {
    return {}
  }

  getErrorsObject({ error }: { readonly error?: FuelCompanyError }): FuelCompanyErrorsObject | null | undefined {
    return error?.errorsObject
  }

  getFieldGroups(): FormFieldGroup[] {
    const fuelCompaniesTextProvider = this.fuelCompaniesI18.getTextProvider()

    return [
      {
        name: mainGroupName,
        visible: false
      },
      {
        name: legalEntityGroupName,
        visible: true,
        title: fuelCompaniesTextProvider.legalEntityField()
      },
      {
        name: contactPersonGroupName,
        visible: true,
        title: fuelCompaniesTextProvider.contactPersonField()
      }
    ]
  }

  getFields(): FormField<FuelCompany, FuelCompanyErrorsObject>[] {
    const fuelCompaniesTextProvider = this.fuelCompaniesI18.getTextProvider()

    return filterNotEmpty([
      new StringFormField<FuelCompany, FuelCompanyErrorsObject>({
        title: fuelCompaniesTextProvider.nameField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "name",
        getValue: (fuelCompany: FuelCompany) => fuelCompany.name,
        setValue: (fuelCompany: FuelCompany, name: string) => ({ ...fuelCompany, name }),
        getErrors: (errorsObject?: FuelCompanyErrorsObject) => errorsObject?.attributes?.name
      }),
      new StringFormField<FuelCompany, FuelCompanyErrorsObject>({
        title: fuelCompaniesTextProvider.codeField(),
        required: true,
        groupName: mainGroupName,
        getId: () => "code",
        getValue: (fuelCompany: FuelCompany) => fuelCompany.code,
        setValue: (fuelCompany: FuelCompany, code: string) => ({ ...fuelCompany, code }),
        getErrors: (errorsObject?: FuelCompanyErrorsObject) => errorsObject?.attributes?.code
      }),
      new BooleanFormField<FuelCompany, FuelCompanyErrorsObject>({
        title: fuelCompaniesTextProvider.blockedField(),
        groupName: mainGroupName,
        getId: () => "blocked",
        getIsVisible: (fuelCompany: FuelCompany) => isPresent(fuelCompany.id),
        getValue: (fuelCompany: FuelCompany) => fuelCompany.blocked,
        setValue: (fuelCompany: FuelCompany, blocked: boolean | null | undefined) => ({ ...fuelCompany, blocked })
      }),
      new StringFormField<FuelCompany, FuelCompanyErrorsObject>({
        title: fuelCompaniesTextProvider.legalEntityNameField(),
        groupName: legalEntityGroupName,
        getId: () => "legalEntityName",
        getValue: (fuelCompany: FuelCompany) => fuelCompany.legalEntity?.name,
        setValue: (fuelCompany: FuelCompany, name: string) => {
          return {
            ...fuelCompany,
            legalEntity: {
              ...fuelCompany.legalEntity,
              name
            }
          }
        }
      }),
      new StringFormField<FuelCompany, FuelCompanyErrorsObject>({
        title: fuelCompaniesTextProvider.legalEntityIndividualTaxpayerNumberField(),
        groupName: legalEntityGroupName,
        getId: () => "legalEntityNameIndividualTaxpayerNumber",
        getValue: (fuelCompany: FuelCompany) => fuelCompany.legalEntity?.individualTaxpayerNumber,
        setValue: (fuelCompany: FuelCompany, individualTaxpayerNumber: string) => {
          return {
            ...fuelCompany,
            legalEntity: {
              ...fuelCompany.legalEntity,
              individualTaxpayerNumber
            }
          }
        },
        getErrors: (errorsObject?: FuelCompanyErrorsObject) => {
          return errorsObject?.legalEntity?.attributes?.individualTaxpayerNumber
        }
      }),
      new StringFormField<FuelCompany, FuelCompanyErrorsObject>({
        title: fuelCompaniesTextProvider.legalEntityTaxRegistrationReasonCode(),
        groupName: legalEntityGroupName,
        getId: () => "legalEntityTaxRegistrationReasonCode",
        getValue: (fuelCompany: FuelCompany) => fuelCompany.legalEntity?.taxRegistrationReasonCode,
        setValue: (fuelCompany: FuelCompany, taxRegistrationReasonCode: string) => {
          return {
            ...fuelCompany,
            legalEntity: {
              ...fuelCompany.legalEntity,
              taxRegistrationReasonCode
            }
          }
        },
        getErrors: (errorsObject?: FuelCompanyErrorsObject) => {
          return errorsObject?.legalEntity?.attributes?.taxRegistrationReasonCode
        }
      }),
      new StringFormField<FuelCompany, FuelCompanyErrorsObject>({
        title: fuelCompaniesTextProvider.contactPersonNameField(),
        groupName: contactPersonGroupName,
        getId: () => "contactPersonName",
        getValue: (fuelCompany: FuelCompany) => fuelCompany.contactPerson?.name,
        setValue: (fuelCompany: FuelCompany, name: string) => {
          return {
            ...fuelCompany,
            contactPerson: {
              ...fuelCompany.contactPerson,
              name
            }
          }
        }
      }),
      new StringFormField<FuelCompany, FuelCompanyErrorsObject>({
        title: fuelCompaniesTextProvider.contactPersonEmailField(),
        groupName: contactPersonGroupName,
        getId: () => "contactPersonEmail",
        getValue: (fuelCompany: FuelCompany) => fuelCompany.contactPerson?.email,
        setValue: (fuelCompany: FuelCompany, email: string) => {
          return {
            ...fuelCompany,
            contactPerson: {
              ...fuelCompany.contactPerson,
              email
            }
          }
        },
        getErrors: (errorsObject?: FuelCompanyErrorsObject) => {
          return errorsObject?.contactPerson?.attributes?.email
        }
      }),
      new PhoneNumberFormField<FuelCompany, FuelCompanyErrorsObject>({
        title: fuelCompaniesTextProvider.contactPersonPhoneNumberField(),
        groupName: contactPersonGroupName,
        getId: () => "contactPersonPhoneNumber",
        getValue: (fuelCompany: FuelCompany) => fuelCompany.contactPerson?.phoneNumber,
        setValue: (fuelCompany: FuelCompany, phoneNumber: string) => {
          return {
            ...fuelCompany,
            contactPerson: {
              ...fuelCompany.contactPerson,
              phoneNumber
            }
          }
        },
        getErrors: (errorsObject?: FuelCompanyErrorsObject) => {
          return errorsObject?.contactPerson?.attributes?.phoneNumber
        }
      })
    ])
  }
}
