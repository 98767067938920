import GasStationBreaksTextProvider from "../GasStationBreaksTextProvider"

export default class DefaultRuGasStationBreaksTextProvider implements GasStationBreaksTextProvider {

  existObjectTitle(): string {
    return "Перерыв в работе АЗС"
  }

  newObjectTitle(): string {
    return "Новый перерыв в работе АЗС"
  }

  listTitle(): string {
    return "Перерывы в работе АЗС"
  }

  gasStationNameField(): string {
    return "АЗС"
  }

  beginDateTimeField(): string {
    return "Начало перерыва"
  }

  endDateTimeField(): string {
    return "Конец перерыва"
  }

  commentField(): string {
    return "Комментарий"
  }

  gasStationFilter(): string {
    return "АЗС"
  }
}
