import React from "react"
import CarrierBalanceView, {
  CarrierBalanceHeaderViewState,
  CarrierBalanceListViewState
} from "./CarrierBalanceView"
import CarrierBalancePresenter from "./CarrierBalancePresenter"
import CarrierBalancesI18n from "../../../i18n/CarrierBalancesI18n"
import HelmetComponent from "../../../../../../admin/core/presentation/components/helmet/HelmetComponent"
import BalanceHeaderComponent from "../../../../../core/presentation/components/balance-header/BalanceHeaderComponent"
import isPresent from "../../../../../../admin/lib/isPresent"
import isBlank from "../../../../../../admin/lib/isBlank"
import { SegmentControlItem } from "../../../../../../admin/lib/segment-control/SegmentControlComponent"
import BalanceTransactionType from "../../../../../core/domain/balance-transactions/BalanceTransactionType"
import TableComponent from "../../../../../../admin/features/objects/presentation/components/table/TableComponent"
import CarrierBalanceTransaction
  from "../../../../../core/domain/carrier-balance-transactions/CarrierBalanceTransaction"
import ExecutionError from "../../../../../../admin/core/domain/entities/errors/ExecutionError"
import ApplicationException from "../../../../../../admin/core/domain/exceptions/ApplicationException"
import styles from "./CarrierBalancePage.module.scss"
import AppUrlProvider from "../../../../../core/presentation/services/AppUrlProvider"
import { Navigate } from "react-router-dom"

interface Props {
  readonly carrierBalancesI18n: CarrierBalancesI18n
  readonly providePresenter: () => CarrierBalancePresenter
  readonly cacheTransactionsType: (transactionsType: string) => void
}

interface State {
  readonly carrierBalanceTransactionsListViewState?: CarrierBalanceListViewState
  readonly carrierBalanceHeaderViewState?: CarrierBalanceHeaderViewState
}

export default class CarrierBalancePage
  extends React.Component<Props, State>
  implements CarrierBalanceView {

  private readonly presenter: CarrierBalancePresenter
  private readonly carrierBalancesI18n: CarrierBalancesI18n

  constructor(props: Props) {
    super(props)
    this.state = {}
    this.presenter = props.providePresenter()
    this.carrierBalancesI18n = props.carrierBalancesI18n
  }

  componentDidMount() {
    this.presenter.attachView(this)
  }

  componentWillUnmount() {
    this.presenter.detachView()
  }

  showCarrierBalanceListViewState(
    carrierBalanceTransactionsListViewState: CarrierBalanceListViewState
  ): void {
    this.setState({
      carrierBalanceTransactionsListViewState
    })
  }

  showCarrierBalanceHeaderViewState(carrierBalanceHeaderViewState: CarrierBalanceHeaderViewState): void {
    this.setState({
      carrierBalanceHeaderViewState
    })
  }

  cacheSelectedTransactionsType(transactionsType: string): void {
    this.props.cacheTransactionsType(transactionsType)
  }

  render() {
    const appUrlProvider = new AppUrlProvider()

    const {
      carrierBalanceTransactionsListViewState,
      carrierBalanceHeaderViewState
    } = this.state

    if (carrierBalanceTransactionsListViewState?.type === "forbidden") {
      return <Navigate to={appUrlProvider.buildForbiddenUrl()} replace={true} />
    }

    if (isBlank(carrierBalanceHeaderViewState) || isBlank(carrierBalanceTransactionsListViewState)) return <></>

    const carrierBalanceTextProvider = this.carrierBalancesI18n.getTextProvider()
    const carrier = (() => {
      switch (carrierBalanceHeaderViewState.type) {
        case "loaded": {
          return carrierBalanceHeaderViewState.carrier
        }
        default:
          return null
      }
    })()

    const {
      table
    } = carrierBalanceTransactionsListViewState

    const pageTitle = isPresent(carrier) ?
      carrierBalanceTextProvider.listTitle({
        carrierName: carrier.name,
        carrierBalance: carrier.balance?.formattedValue
      }) : carrierBalanceTextProvider.listTitle()

    const segmentControlItems: SegmentControlItem[] = [
      {
        id: BalanceTransactionType.REPLENISHMENT,
        text: carrierBalanceTextProvider.replenishmentsSegment()
      },
      {
        id: BalanceTransactionType.WRITE_OFF,
        text: carrierBalanceTextProvider.writeOffsSegment()
      }
    ]

    const selectedSegmentControlItems = segmentControlItems
      .find((segmentControlItem) => segmentControlItem.id === carrierBalanceHeaderViewState.transactionType)

    const transactions: CarrierBalanceTransaction[] = (() => {
      switch (carrierBalanceTransactionsListViewState.type) {
        case "loaded":
        case "next_page_loading":
        case "next_page_loading_error":
        case "next_page_loading_failure": {
          return carrierBalanceTransactionsListViewState.items
        }
        default:
          return []
      }
    })()

    const transactionsLoadingError: ExecutionError | undefined = (() => {
      switch (carrierBalanceTransactionsListViewState.type) {
        case "loading_error":
        case "next_page_loading_error": {
          return carrierBalanceTransactionsListViewState.error
        }
        default:
          return undefined
      }
    })()

    const transactionsLoadingException: ApplicationException | undefined = (() => {
      switch (carrierBalanceTransactionsListViewState.type) {
        case "loading_failure":
        case "next_page_loading_failure": {
          return carrierBalanceTransactionsListViewState.exception
        }
        default:
          return undefined
      }
    })()

    const transactionsIsLoading = carrierBalanceTransactionsListViewState.type === "initializing" ||
      carrierBalanceTransactionsListViewState.type === "loading" ||
      carrierBalanceTransactionsListViewState.type === "next_page_loading"

    return (
      <>
        <HelmetComponent
          title={pageTitle}
        />
        {carrierBalanceHeaderViewState.type !== "initializing" && (
          <BalanceHeaderComponent
            ownerName={carrier?.name ?? pageTitle}
            formattedBalanceValue={carrier?.balance?.formattedValue ?? undefined}
            needShowAction={table.getCanCreateNewObject() === true}
            action={{
              url: carrier && appUrlProvider.buildCarrierBalanceReplenishmentUrl({
                carrierId: carrier.id!
              }),
              name: carrierBalanceTextProvider.replenishBalance()
            }}
            segmentControlItems={segmentControlItems}
            selectedSegmentControlItem={selectedSegmentControlItems}
            onSelectSegmentControlItem={(segmentControlItem) => {
              this.presenter.onSegmentSelected(segmentControlItem.id)
            }}
          />
        )}
        <TableComponent
          className={styles.table}
          table={table}
          objects={transactions}
          isLoading={transactionsIsLoading}
          exception={transactionsLoadingException}
          error={transactionsLoadingError}
          onNextPageRequested={this.presenter.onNextPageRequested}
          onRetryLoadClicked={this.presenter.onRetryLoadClicked}
        />
      </>
    )
  }
}
